<gep-deals-tab-header></gep-deals-tab-header>
<main>
  <div class="d-flex justify-content-end mb-3">
    <eon-ui-button
      class="enquiry_btn"
      text="{{ 'new enquiry' | translate }}"
      icon="add"
      [size]="ButtonSize.Small"
      scheme="turquoise"
      use-as-link="true"
      href="/enquiry"
    ></eon-ui-button>
  </div>

  <gep-ag-grid-table [rowData$]="offersData$" [colDefs]="colDef" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"></gep-ag-grid-table>

  <div class="d-flex flex-row justify-content-end legend">
    <span class="d-flex flex-row"><gep-icon [iconType]="Icon.Info" [size]="ButtonSize.Small"></gep-icon>: {{ 'offersPage.informDeal' | translate }}</span>
    <span class="d-flex flex-row"><gep-icon [iconType]="Icon.Rewind" [size]="ButtonSize.Small"></gep-icon>: {{ 'offersPage.backToPriceCollection' | translate }} </span>
    <span class="d-flex flex-row"><gep-icon [iconType]="Icon.ValueProposition" [size]="ButtonSize.Small"></gep-icon>:  {{ 'offersPage.closeDeal' | translate }} </span>
    <span class="d-flex flex-row"><gep-icon [iconType]="Icon.Copy" [size]="ButtonSize.Small"></gep-icon>:  {{ 'offersPage.cloneDeal' | translate }} </span>
    <span class="d-flex flex-row"><gep-icon [iconType]="Icon.Edit" [size]="ButtonSize.Small"></gep-icon>: {{ 'offersPage.editDeal' | translate }} </span>
    <span class="d-flex flex-row"><gep-icon [iconType]="Icon.Close" [size]="ButtonSize.Small"></gep-icon>: {{ 'offersPage.cancelDeal' | translate }}</span>
  </div>
</main>

<eon-ui-modal
  [visible]="showConfirmModal$ | async"
  [headlineText]="'CONFIRM' | translate"
  show-closing-x="true"
  (modalClose)="onConfirm(false)"
>
  <div class="modal-entry-container">
    {{ 'offersPage.confirmCancel' | translate }}
  </div>
  <div class="confirm-action-buttons">
    <eon-ui-button
      class="action-buttons"
      [text]="'offersPage.cancel' | translate"
      scheme="turquoise"
      size="small"
      (click)="onConfirm(false)"
    >
    </eon-ui-button>
    <eon-ui-button
      class="action-buttons"
      [text]="'offersPage.confirm' | translate"
      scheme="highlight"
      size="small"
      (click)="onConfirm(true)"
    >
    </eon-ui-button>
  </div>
</eon-ui-modal>

<eon-ui-modal
  [visible]="informModal.visible"
  modal-id="inform-modal"
  [headlineText]="'INFORM' | translate"
  show-closing-x="true"
  (modalClose)="resetInformModal()"
>
  <div>
    <div class="d-flex justify-content-end">
      <div class="select-all">
        <div>
          <b>{{ 'SELECT_ALL' | translate }}</b>
        </div>
        <div class="select-all-checkbox">
          <eon-ui-checkbox
            id="selectAll"
            value="{{ true }}"
            ngDefaultControl
            [checked]="informModal.selectAll"
            (valueChanged)="selectAllCounterparts($event)"
          >
          </eon-ui-checkbox>
        </div>
      </div>
    </div>

    <div class="modal-entry-container">
      <gep-checkbox-tree #gepCheckboxTree [items]="informModal.counterpartUsersTree" (itemsChange)="onInformModalCheckboxChange($event)"></gep-checkbox-tree>
    </div>
  </div>

  <eon-ui-button
    class="action-buttons"
    text="{{
    (countCounterpartsToBeInformed$ | async)!
      | informCounterpartButton: informModal.deal?.status
      | translate
  }}"
    scheme="turquoise"
    size="small"
    (click)="notify(informModal.deal)"
    [attr.disabled]="informModal.disableSendButton"
  >
  </eon-ui-button>
</eon-ui-modal>

<eon-ui-modal
  [visible]="offersModal.visible"
  modal-id="prices-modal"
  [headlineText]="'PRICE_OFFERS' | translate"
  show-closing-x="true"
  (modalClose)="resetOffersModal()"
>
  <gep-offer-quickview [deal]="offersModal.deal"></gep-offer-quickview>

  <gep-manual-offer
    [priceCollection]="offersModal.deal?.status === STATUS.PRICE_COLLECTION"
    [deal]="offersModal.deal"
    (offerRecorded)="refreshDealsAfterManualEdit(offersModal.deal!)"
  ></gep-manual-offer>
  <div class="offer-modal">
    <div class="modal-entry-container" *ngFor="let offer of offersModal.offers">
      <div class="modal-entry-col">
        <div>
          {{ 'PRICE' | translate }}:  <b> {{ offer.price }} €</b>
        </div>

        <eon-ui-button
          *ngIf="offersModal.deal?.status === STATUS.PRICE_COLLECTION"
          class="action-buttons"
          text="{{ 'BIND' | translate }}"
          scheme="turquoise"
          size="small"
          [disabled]="offersModal.bindingSuccessful"
          (click)="bind(offersModal.deal, offer)"
        >
        </eon-ui-button>
        <!-- Display success/error messages when "Bind"-Button is clicked -->
        <div *ngIf="offersModal.loading === false">
          <eon-ui-text
            color="eon-turquoise"
            *ngIf="offersModal.bindingSuccessful; else bindingUnsuccessful"
          >
            {{ 'BINDING_SUCCESSFUL' | translate }}
          </eon-ui-text>
          <ng-template #bindingUnsuccessful>
            <eon-ui-text color="eon-red">{{
                'BINDING_UNSUCCESSFUL' | translate
              }}</eon-ui-text>
          </ng-template>
        </div>
        <div class="offer-bound-container" *ngIf="offer.bound_date">
          <div>
            {{ 'BOUND_DATE' | translate }}:
            <b
            >{{
                offer.bound_date
                  ? (offer.bound_date | date: 'dd.MM.yyyy HH:mm')
                  : ''
              }}
            </b>
          </div>
          <div>
            {{ 'BOUND_BY' | translate }}: <b>{{ offer.bound_by }}</b>
          </div>
        </div>
      </div>
      <div class="offer-counterpart">
        <div>
          {{ 'manual_deal_offer_counterpart' | translate }}:
          <b>{{ offer.counterpart_name }}</b>, <small>{{ offer.counterpart_company }}</small>
        </div>
        <div>
          {{ 'OFFER_DATE' | translate }}:
          <b>{{ offer.created_at | localizeDate }}</b>
        </div>
        <div *ngIf="offer.updated_at">
          {{ 'OFFER_UPDATED' | translate }}:
          <b>{{ offer.updated_at | localizeDate }}</b>
        </div>
      </div>
    </div>
  </div>
</eon-ui-modal>


<gep-modal
  [visible]="dealModal.visible"
  [headline]="'offersPage.confirmClosedHeadline' | translate"
  [description]="
('offersPage.confirmClosedQuestion' | translate) +
'<br><small>' + ('quantity' | translate) + ': ' + dealModal.deal?.quantityInMWh + ' MWh, ' +
('year' | translate) + ': ' + dealModal.deal?.year + ', ' + ('customer' | translate) + ': ' + dealModal.deal?.customer + '</small>'
"
  [positiveButtonStyle]="Style.Highlight"
  [closeButtonText]="'no' | translate"
  [positiveButtonText]="'yes' | translate"
  (positiveButtonClicked)="close(dealModal.deal!)"
  (closedButtonClicked)="dealModal.close()"
  [showClosingX]="true"
  modalId="show-close-deal-confirmation"
></gep-modal>
