import { Component } from '@angular/core';
import {
  ColDef,
  GridOptions,
  RowClassParams,
  RowStyle,
} from 'ag-grid-community';
import { Observable, startWith } from 'rxjs';
import {
  HolidayCalendar,
  HolidayCalendarEntry,
} from '../../../../models/holiday-calendar-entry.model';
import { HolidayCalendarService } from '../../../../services/tracker/holiday-calendar.service';
import { OptionTransformers } from '../../../../transformers/option-transformers';
import { DateRendererComponent } from '../../../../modules/ag-grid/renderer/date-renderer/date-renderer.component';
import { ButtonSize } from '../../../../modules/gep-controls/models/button-size';
import { switchMap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'gep-tracker-calendar-page',
  templateUrl: './tracker-calendar-page.component.html',
  styleUrls: ['./tracker-calendar-page.component.scss'],
})
export class TrackerCalendarPageComponent {
  protected readonly OptionTransformers = OptionTransformers;
  protected readonly ButtonSize = ButtonSize;

  formGroup: FormGroup = new FormGroup({
    calendarName: new FormControl(),
  });

  protected readonly rowData$: Observable<HolidayCalendarEntry[]> =
    this.formGroup.get('calendarName')!.valueChanges.pipe(
      startWith('EEX-Calendar'),
      switchMap(selectedCalendar => {
        return this.holidayCalendarService.getHolidayCalendarEntries$(
          selectedCalendar
        );
      })
    );

  constructor(private holidayCalendarService: HolidayCalendarService) {}

  readonly holidayCalendars$: Observable<HolidayCalendar[]> =
    this.holidayCalendarService.getHolidayCalendars$();

  colDefs: ColDef<HolidayCalendarEntry>[] = [
    {
      field: 'date',
      cellRenderer: DateRendererComponent,
    },
    {
      field: 'holidayName',
    },
  ];

  gridOptions: GridOptions = {
    getRowId: params => params.data.date,
  };

  defaultColDef: ColDef = {
    flex: 1,
    cellDataType: false,
  };

  getRowStyle = ({ node }: RowClassParams) =>
    node.rowPinned
      ? ({ fontWeight: 'bold', fontStyle: 'italic' } as RowStyle)
      : {};
}
