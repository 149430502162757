import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrackerDefaultModel } from '../../models/trackerdefault.model';
import { environment } from '../../../environments/environment';
import { EnquiryDefaultModel } from '../../models/enquirydefault.model';
import { HttpClient } from '@angular/common/http';
import { LabelValueModel } from '../../models/label-value.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TrackerDefaultsService {
  constructor(private http: HttpClient) {}

  getTrackerDefaults$(): Observable<TrackerDefaultModel[]> {
    return this.http.get<TrackerDefaultModel[]>(
      `${environment.API}v1/tracker/defaults`
    );
  }

  getTrackerDefaultsForLabelValue$(): Observable<LabelValueModel[]> {
    return this.http
      .get<TrackerDefaultModel[]>(`${environment.API}v1/tracker/defaults`)
      .pipe(
        map(templates => {
          const result: LabelValueModel[] = [];

          templates.map(template => {
            const singleProduct: LabelValueModel = {
              label: template.name!,
              value: template.id!,
            };

            result.push(singleProduct);
          });
          return result;
        })
      );
  }

  getTrackerDefaultsById$(
    id: string
  ): Observable<TrackerDefaultModel | undefined> {
    return this.http.get<TrackerDefaultModel>(
      `${environment.API}v1/tracker/defaults/${id}`
    );
  }

  saveTrackerDefault$(
    trackerDefaultModel: TrackerDefaultModel
  ): Observable<any> {
    trackerDefaultModel.customer = 'ESG';
    if (trackerDefaultModel.id) {
      return this.http.put<TrackerDefaultModel>(
        `${environment.API}v1/tracker/defaults`,
        trackerDefaultModel
      );
    } else {
      return this.http.post<EnquiryDefaultModel>(
        `${environment.API}v1/tracker/defaults`,
        trackerDefaultModel
      );
    }
  }

  deleteTrackerDefault$(id: string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.API}v1/tracker/defaults/${id}`
    );
  }
}
