import { DealModel } from '../../models/deal.model';
import { OfferModel } from '../../models/offer.model';
import { TreeNode } from '../../models/tree-node.model';

export function sortOffers(deal: DealModel): OfferModel[] | undefined {
  if (!deal.quantityInMWh || deal.quantityInMWh >= 0) {
    return deal.offers
      ?.sort((a, b) =>
        (a.updated_at || a.created_at) < (b.updated_at || b.created_at) ? 1 : -1
      )
      .sort((a, b) => a.price - b.price);
  } else {
    return deal.offers
      ?.sort((a, b) =>
        (a.updated_at || a.created_at) < (b.updated_at || b.created_at) ? 1 : -1
      )
      .sort((a, b) => b.price - a.price);
  }
}

export function createTree(data: any[]): TreeNode[] {
  const tree: TreeNode[] = [];
  const groupByCompanyName = data.reduce((groups, item) => {
    const group = groups[item.company_name] || [];
    group.push(item);
    groups[item.company_name] = group;
    return groups;
  }, {});

  for (const companyName in groupByCompanyName) {
    const children = groupByCompanyName[companyName].map((item: any) => {
      return {
        label: item.name,
        value: item.oid,
        checked: item.notify_by_email,
      } as TreeNode;
    });

    tree.push({
      label: companyName,
      children: children,
      expanded: false,
    });
  }

  return tree;
}
