import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, startWith, Subject } from 'rxjs';
import { Icon } from '../../../gep-controls/models/icon';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DEAL_TYPES } from '../../../../services/deal.service';
import { ProductService } from '../../../../services/product.service';
import { SettingsService } from '../../../../services/settings.service';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Style } from '../../../gep-controls/models/style';
import { OptionTransformers } from '../../../../transformers/option-transformers';
import { LabelValueModel } from '../../../../models/label-value.model';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'gep-enquiry-templates',
  templateUrl: './enquiry-templates.component.html',
  styleUrls: ['./enquiry-templates.component.scss'],
})
export class EnquiryTemplatesComponent implements OnInit {
  private enquiryDefaultListChangedSubject = new Subject<void>();
  enquiryDefaultListChanged$ =
    this.enquiryDefaultListChangedSubject.asObservable();

  templates$!: Observable<LabelValueModel[]>;

  form: FormGroup = new FormGroup<any>({
    contact_person: new FormControl(undefined, [Validators.required]),
    customer: new FormControl(undefined, [Validators.required]),
    address: new FormControl(undefined, [Validators.required]),
    deal_type: new FormControl(undefined, [Validators.required]),
    vg: new FormControl(undefined, [Validators.required]),
    product_key: new FormControl(undefined, [Validators.required]),
    segment: new FormControl(undefined, [Validators.required]),
    redistributor: new FormControl(undefined, [Validators.required]),
    binding_period_end: new FormControl(),
    binding_period_start: new FormControl(),
    id: new FormControl(),
  });

  dealTypes: string[] = Object.values(DEAL_TYPES).filter(x =>
    x.includes('ENQUIRY')
  );
  segments$: Observable<string[]> = this.productService.getSegments$();
  vgs$: Observable<string[]> = this.productService.getVgs$();
  products$: Observable<string[]> = this.productService
    .getProductConfigurations$()
    .pipe(map(x => x.map(y => y.key)));

  showForm: boolean = false;
  isNewTemplate: boolean = false;
  deleteModalVisible: boolean = false;

  protected readonly Icon = Icon;
  protected readonly Style = Style;
  protected readonly OptionTransformers = OptionTransformers;

  constructor(
    protected productService: ProductService,
    private settingsService: SettingsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.templates$ = this.enquiryDefaultListChanged$.pipe(
      startWith(null),
      switchMap(() => this.settingsService.getEnquiryDefaults$())
    );
  }

  createNewTemplate() {
    this.showForm = true;
    this.isNewTemplate = true;
    this.form.reset();
  }

  showDeleteModal() {
    this.deleteModalVisible = true;
  }

  deleteTemplate() {
    this.settingsService
      .deleteEnquiryDefault$(<string>this.form.get('id')?.value)
      .pipe(take(1))
      .subscribe(_ => {
        this.deleteModalVisible = false;
        this.resetView();
        this.enquiryDefaultListChangedSubject.next();
      });
  }

  saveTemplate() {
    this.settingsService
      .saveEnquiryDefault$(this.form.value)
      .pipe(take(1))
      .subscribe(_ => {
        this.toastService.showSavedToast();
        if (this.isNewTemplate) {
          this.resetView();
        }
        this.enquiryDefaultListChangedSubject.next();
      });
  }

  selectTemplate(templateName: string) {
    this.showForm = true;
    this.isNewTemplate = false;
    this.form.reset();
    this.settingsService
      .getEnquiryDefaultsById$(templateName)
      .pipe(take(1))
      .subscribe(data => {
        if (data) {
          this.form.patchValue(data);
        }
      });
  }

  resetView() {
    this.form.reset();
    this.showForm = false;
  }
}
