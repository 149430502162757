import { Component } from '@angular/core';
import { EditEnquiryFacadeService } from '../../services/edit-enquiry-facade.service';
import { Icon } from '../../../gep-controls/models/icon';
import { DealModel } from '../../../../models/deal.model';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'gep-edit-enquiry',
  templateUrl: './edit-enquiry.component.html',
  styleUrls: ['./edit-enquiry.component.scss'],
})
export class EditEnquiryComponent {
  readonly deal$ = this.facade.deal$;

  readonly selectedDealId$: Observable<string> = this.facade.selectedDealId$;

  readonly productConfigurations$ = this.facade.productConfigurations$;

  protected readonly Icon = Icon;

  constructor(
    private readonly facade: EditEnquiryFacadeService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly toastService: ToastService
  ) {
    facade.activateRoute(activatedRoute);
  }

  onSave(deal: DealModel[]) {
    this.selectedDealId$
      .pipe(
        take(1),
        switchMap(dealId => this.facade.updateDeal$(dealId, deal[0]))
      )
      .subscribe(_ => {
        this.toastService.showSavedToast();
        this.router.navigate(['/offers']);
      });
  }

  onEditCancel() {
    this.router.navigate(['/offers']);
  }
}
