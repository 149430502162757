import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { SCHEDULE_TYPES, TrackerModel } from '../../../models/tracker.model';

export function createFormGroup(
  trackerId: string | null,
  formModel?: TrackerModel
): FormGroup {
  return new FormBuilder().group(
    {
      id: [trackerId],
      name: [formModel?.name, Validators.required],
      customer: ['EDG'],
      vg: [formModel?.vg, Validators.required],
      segment: [formModel?.segment, Validators.required],
      productKey: [formModel?.productKey, Validators.required],
      year: [formModel?.year, Validators.required],
      psiId: [formModel?.psiId, Validators.required],
      dealType: [formModel?.dealType, Validators.required],
      sourcingStart: [
        formModel?.sourcingStart
          ? new Date(formModel.sourcingStart)
          : undefined,
        Validators.required,
      ],
      sourcingEnd: [
        formModel?.sourcingEnd ? new Date(formModel.sourcingEnd) : undefined,
        Validators.required,
      ],
      totalQuantity: [formModel?.totalQuantity, Validators.required],
      scheduleType: [formModel?.scheduleType, Validators.required],
      scheduledDay: [formModel?.scheduledDay],
      scheduledDayOfMonth: [formModel?.scheduledDayOfMonth],
      schedulingTime: [formModel?.schedulingTime],
      holidayCalendarName: [
        formModel?.holidayCalendarName,
        Validators.required,
      ],
      active: [formModel?.active],
      closedQuantity: [formModel?.closedQuantity],
    },
    { validators: [dateRangeValidator, totalQuantityValidator] }
  );
}

export function setValidatorsForDayFields(
  form: FormGroup,
  selectedSchedulingType: SCHEDULE_TYPES
) {
  const schedulingDayOfWeekField = form.get('scheduledDay');
  const schedulingDayOfMonthField = form.get('scheduledDayOfMonth');

  if (selectedSchedulingType === SCHEDULE_TYPES.WEEKLY) {
    schedulingDayOfMonthField?.reset();
    schedulingDayOfMonthField?.setValidators(null);
    schedulingDayOfWeekField?.setValidators([Validators.required]);
  } else if (selectedSchedulingType === SCHEDULE_TYPES.MONTHLY) {
    schedulingDayOfWeekField?.reset();
    schedulingDayOfWeekField?.setValidators(null);
    schedulingDayOfMonthField?.setValidators([Validators.required]);
  } else if (selectedSchedulingType === SCHEDULE_TYPES.DAILY) {
    schedulingDayOfMonthField?.reset();
    schedulingDayOfWeekField?.reset();
    schedulingDayOfMonthField?.setValidators(null);
    schedulingDayOfWeekField?.setValidators(null);
  }
  schedulingDayOfWeekField?.updateValueAndValidity();
  schedulingDayOfMonthField?.updateValueAndValidity();
}

export function generateYearRange(years: number): number[] {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: years }, (_, i) => currentYear - 2 + i);
}

export function generateRange(start: number, end: number): number[] {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

export function getTomorrowDate(): Date {
  return new Date(new Date().setDate(new Date().getDate() + 1));
}

export function dateRangeValidator(
  group: AbstractControl
): ValidationErrors | null {
  const sourcingStart = group.get('sourcingStart')?.value;
  const sourcingEnd = group.get('sourcingEnd')?.value;
  return sourcingStart &&
    sourcingEnd &&
    new Date(sourcingStart) >= new Date(sourcingEnd)
    ? { dateRangeEndBeforeStart: true }
    : null;
}

export function totalQuantityValidator(
  group: AbstractControl
): ValidationErrors | null {
  const totalQuantity = group.get('totalQuantity')?.value;
  const closedQuantity = group.get('closedQuantity')?.value;
  return closedQuantity && totalQuantity <= closedQuantity
    ? { totalQuantitySmallerThanClosedQuantityInvalid: true }
    : null;
}
