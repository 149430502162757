import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Column } from '../column';
import { PageModel } from '../../../models/page.model';

@Component({
  selector: 'gep-pagination-table',
  templateUrl: './pagination-table.component.html',
  styleUrls: ['./pagination-table.component.scss'],
})
export class PaginationTableComponent {
  @Input()
  public columns!: Column[];

  private _page!: PageModel<any>;

  @Input()
  public set page(value: PageModel<any>) {
    this._page = value;
  }

  public get page(): PageModel<any> {
    return this._page;
  }

  @Input()
  public small = false;

  @Output()
  public paramsChanged = new EventEmitter<void>();

  columnChanged(index: number, column: Column) {
    if (column.sort != null) {
      this.unsetSortSettingsExcluding(index);
    }

    this.paramsChanged.emit();
  }

  private unsetSortSettingsExcluding(index: number) {
    this.getColumnsExcluding(index).forEach(column => {
      column.sort = null;
    });
  }

  private getColumnsExcluding(index: number) {
    return this.columns.filter((_, index1) => index1 != index);
  }
}
