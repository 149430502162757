import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { runOnceUntilComplete } from '../effects/run-once.effect';

@Injectable({
  providedIn: 'root',
})
export class PsiService {
  constructor(private http: HttpClient) {}
  debounce = runOnceUntilComplete();
  public uploadPsiExcel(
    file: File
  ): Observable<{ uploaded_file: string; imported: any }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.debounce(() => {
      return this.http.post<{ uploaded_file: string; imported: any }>(
        environment.API + 'v1/import/psi-sales-manual',
        formData
      );

    });
  }
}
