import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, of } from 'rxjs';
import {
  combineLatestWith,
  debounceTime,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
  distinctUntilChanged,
  take,
} from 'rxjs/operators';
import { ProductConfigModel } from '../../../../../models/product-config.model';
import { ProductService } from '../../../../../services/product.service';
import {
  SCHEDULE_TYPES,
  SCHEDULED_DAYS,
  TRACKER_DEAL_TYPES,
  TrackerModel,
} from '../../../../../models/tracker.model';
import { HolidayCalendarService } from '../../../../../services/tracker/holiday-calendar.service';
import { TrackerService } from '../../../../../services/tracker/tracker.service';
import { HolidayCalendar } from '../../../../../models/holiday-calendar-entry.model';
import { TrackerSummary } from '../../../../../models/tracker-summary.model';
import { OptionTransformers } from '../../../../../transformers/option-transformers';
import { Icon } from '../../../../../modules/gep-controls/models/icon';
import { ButtonSize } from '../../../../../modules/gep-controls/models/button-size';
import { Style } from '../../../../../modules/gep-controls/models/style';
import {
  createFormGroup,
  generateRange,
  generateYearRange,
  getTomorrowDate,
  setValidatorsForDayFields,
} from '../../../../../modules/tracker/utils/tracker-form.functions';
import { FormGroup } from '@angular/forms';

const MAXIMUM_YEARS = 15;

@Component({
  selector: 'gep-tracker-form',
  templateUrl: './tracker-form.component.html',
  styleUrls: ['./tracker-form.component.scss'],
})
export class TrackerFormComponent {
  private readonly injectedProductConfigurations$ = new ReplaySubject<
    ProductConfigModel[]
  >(1);
  trackerData$ = new ReplaySubject<TrackerModel | undefined>(1);
  prefillForm$ = new ReplaySubject<TrackerModel | null>(1);

  @Input()
  set productConfigurations(value: ProductConfigModel[]) {
    this.injectedProductConfigurations$.next(value);
  }

  @Input()
  set tracker(value: TrackerModel | undefined) {
    this.trackerData$.next(value);
  }

  @Input()
  set prefillForm(value: TrackerModel | null) {
    this.prefillForm$.next(value);
  }

  @Input()
  trackerId: string | null = null;

  productConfigurations$: Observable<ProductConfigModel[]> =
    this.injectedProductConfigurations$.pipe(shareReplay(1));
  readonly productOptions$: Observable<string[]> =
    this.productConfigurations$.pipe(
      map(configs => configs.map(config => config.key))
    );
  readonly salesCompanies$: Observable<string[]> =
    this.productService.getVgs$();
  readonly segments$: Observable<string[]> = this.productService.getSegments$();
  readonly holidayCalendars$: Observable<HolidayCalendar[]> =
    this.holidayCalendarService.getHolidayCalendars$();

  readonly deliveryYears: number[] = generateYearRange(MAXIMUM_YEARS);
  readonly daysOfMonth: number[] = generateRange(1, 28);
  readonly tomorrow: Date = getTomorrowDate();

  readonly schedulingTypes = Object.keys(SCHEDULE_TYPES);
  readonly daysOfWeek = Object.keys(SCHEDULED_DAYS);
  readonly dealTypes = Object.keys(TRACKER_DEAL_TYPES);

  readonly Icon = Icon;
  readonly ButtonSize = ButtonSize;
  readonly Style = Style;
  readonly OptionTransformers = OptionTransformers;

  constructor(
    private readonly productService: ProductService,
    private readonly holidayCalendarService: HolidayCalendarService,
    private readonly router: Router,
    readonly trackerService: TrackerService
  ) {}

  readonly formGroup$ = this.trackerData$.pipe(
    combineLatestWith(this.productConfigurations$),
    map(([tracker]) => createFormGroup(this.trackerId, tracker))
  );

  readonly form$ = this.prefillForm$.pipe(
    startWith(null),
    combineLatestWith(this.formGroup$),
    map(([prefill, form]) => {
      if (prefill) {
        form.patchValue({ ...prefill });
      }
      return form;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  readonly sourcingStartDisabled$: Observable<boolean> = this.form$.pipe(
    map(form => this.isSourcingStartInPast(form))
  );

  readonly sourcingStartMinimumDate$: Observable<Date | null> = this.form$.pipe(
    debounceTime(100),
    map(form => this.getMinimumDateForSourcingStart(form))
  );

  readonly showDayOfWeek$: Observable<boolean> = this.form$.pipe(
    switchMap(form =>
      form.get('scheduleType')!.valueChanges.pipe(
        tap(schedulingType => setValidatorsForDayFields(form, schedulingType)),
        map(schedulingType => schedulingType === SCHEDULE_TYPES.WEEKLY)
      )
    ),
    startWith(false)
  );

  readonly showDayOfMonth$: Observable<boolean> = this.form$.pipe(
    switchMap(form =>
      form.get('scheduleType')!.valueChanges.pipe(
        tap(schedulingType => setValidatorsForDayFields(form, schedulingType)),
        map(schedulingType => schedulingType === SCHEDULE_TYPES.MONTHLY)
      )
    ),
    startWith(false)
  );

  readonly trackerPreviewResult$: Observable<TrackerSummary> = this.form$.pipe(
    switchMap(form =>
      form.valueChanges.pipe(
        debounceTime(400),
        switchMap(() =>
          form.valid
            ? this.trackerService.getTrackerSummary$(form.value)
            : of({} as TrackerSummary)
        )
      )
    )
  );

  readonly selectedProduct$: Observable<ProductConfigModel | undefined> =
    this.form$.pipe(
      switchMap(form =>
        form.get('productKey')!.valueChanges.pipe(
          combineLatestWith(this.productConfigurations$),
          map(([productKey, products]) =>
            products.find(product => product.key === productKey)
          )
        )
      )
    );

  saveTracker(trackerData: TrackerModel) {
    this.trackerService
      .saveTracker$(trackerData)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate(['/tracker/masterdata']);
      });
  }

  private getMinimumDateForSourcingStart(form: FormGroup): Date | null {
    const trackerId = form.get('id')?.value;
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (trackerId) {
      const sourcingStart = form.get('sourcingStart')?.value;
      const sourcingStartDate = sourcingStart ? new Date(sourcingStart) : null;

      if (sourcingStartDate && sourcingStartDate < today) {
        return null;
      } else {
        return tomorrow;
      }
    } else {
      return tomorrow;
    }
  }

  private isSourcingStartInPast(form: FormGroup): boolean {
    const trackerId = form.get('id')?.value;
    if (trackerId) {
      const sourcingStart = form.get('sourcingStart');
      const today = new Date();

      if (!sourcingStart || !sourcingStart.value) {
        return false;
      }

      return new Date(sourcingStart.value) < today;
    }

    return false;
  }
}
