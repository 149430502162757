import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  mergeWith,
  ReplaySubject,
  Subject,
  withLatestFrom,
} from 'rxjs';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import {
  datePickerStringToDate,
  dateToDatepickerString,
} from '../../utils/datepicker.functions';
import { ButtonSize } from '../../../gep-controls/models/button-size';

@Component({
  selector: 'gep-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input()
  label?: string;

  @Input()
  showWeekends: boolean = false;

  @Input()
  size: ButtonSize = ButtonSize.Normal;

  @Input()
  dateFormat: string = 'dd.MM.yyyy';

  @Output()
  valueSelected = new EventEmitter<Date>();

  readonly minDate$ = new BehaviorSubject<string>('1950-01-01');
  @Input()
  set minimumDate(value: Date | null) {
    if (value !== null) {
      this.minDate$.next(
        `${value.getFullYear()}-${(value.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${value.getDate().toString().padStart(2, '0')}`
      );
    }
  }

  @Input()
  disabled?: boolean = false;

  private readonly valueChanged$ = new Subject<Date>();
  private readonly value$ = new ReplaySubject<Date>();

  private readonly mergedValue$ = this.valueChanged$.pipe(
    withLatestFrom(this.value$),
    map(([valueChange, writtenValue]) => {
      const mergedDate = new Date(valueChange);
      if (!!writtenValue) {
        mergedDate.setHours(writtenValue.getHours());
        mergedDate.setMinutes(writtenValue.getMinutes());
        mergedDate.setSeconds(writtenValue.getSeconds());
        mergedDate.setMilliseconds(writtenValue.getMilliseconds());
      }
      return mergedDate;
    }),
    map(changedDate => {
      if (!!this.changeFn) {
        this.changeFn(changedDate);
      }

      return changedDate;
    })
  );

  readonly valueFormatted$ = this.value$.pipe(
    mergeWith(this.mergedValue$),
    distinctUntilChanged((a, b) => {
      const isoDateA = !!a ? a.toISOString().split('T')[0] : null;
      const isoDateB = !!b ? b.toISOString().split('T')[0] : null;
      return isoDateA === isoDateB;
    }),
    map(value => {
      if (!!value) {
        return dateToDatepickerString(value);
      }
      return null;
    })
  );

  private changeFn?: (change: Date) => void;
  private touchFn?: () => void;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: (change: Date) => void): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchFn = fn;
  }

  writeValue(obj: any): void {
    this.value$.next(obj);
  }

  onValueChanged({ detail }: any) {
    const selectedDate = datePickerStringToDate(detail);
    this.valueChanged$.next(selectedDate);
    this.valueSelected.emit(selectedDate);
  }
}
