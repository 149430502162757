import { Component } from '@angular/core';
import { DealModel, Status } from '../../../../models/deal.model';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'gep-product-status-renderer',
  templateUrl: './product-status-renderer.component.html',
})
export class ProductStatusRendererComponent
  implements ICellRendererAngularComp
{
  protected readonly Status = Status;

  row?: DealModel;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.row = params.node.data;
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
