import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductConfigurationComponent } from './components/product-configuration/product-configuration.component';
import { EonUiComponentsAngularModule } from '@eon-ui/eon-ui-components-angular';
import { ComponentsModule } from '../../components/components.module';
import { GepFormsModule } from '../gep-forms/gep-forms.module';
import { DealsModule } from '../deals/deals.module';
import { ReactiveFormsModule } from '@angular/forms';
import { GepControlsModule } from '../gep-controls/gep-controls.module';
import { EnquiryTemplatesComponent } from './components/enquiry-templates/enquiry-templates.component';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonsModule } from '../commons/commons.module';
import { AdminModule } from '../admin/admin.module';
import { CounterpartUsersComponent } from './components/counterpart-users/counterpart-users.component';
import { AgGridModule } from '../ag-grid/ag-grid.module';
import { SettingsTabHeaderComponent } from './components/settings-tab-header/settings-tab-header.component';
import { RouterModule } from '@angular/router';
import { TrackerDefaultsPageComponent } from './components/tracker-defaults-page/tracker-defaults-page.component';

@NgModule({
  declarations: [
    ProductConfigurationComponent,
    EnquiryTemplatesComponent,
    GeneralSettingsComponent,
    CounterpartUsersComponent,
    SettingsTabHeaderComponent,
    TrackerDefaultsPageComponent,
  ],
  exports: [
    ProductConfigurationComponent,
    EnquiryTemplatesComponent,
    GeneralSettingsComponent,
  ],
  imports: [
    CommonModule,
    EonUiComponentsAngularModule,
    ComponentsModule,
    GepFormsModule,
    DealsModule,
    ReactiveFormsModule,
    GepControlsModule,
    TranslateModule,
    CommonsModule,
    AdminModule,
    AgGridModule,
    RouterModule,
  ],
})
export class SettingsModule {}
