<gep-tracker-navbar></gep-tracker-navbar>

<main>
  <section>
    <div
      class="enquiry-templates"
      *ngIf="prefillOptions$ | async as prefillOptions"
    >
      <gep-dropdown
        *ngIf="(trackerId$ | async) === null"
        [options]="prefillOptions"
        [optionLabelTransformer]="
          OptionTransformers.trackerDefaultsLabelTransformer
        "
        [label]="'createEnquiry.prefillFormDropdown' | translate"
        [formControl]="prefillDropdownControl"
      ></gep-dropdown>
    </div>
    <ng-container
      *ngIf="productConfigurations$ | async as productConfigurations"
    >
      <gep-tracker-form
        [productConfigurations]="productConfigurations"
        [tracker]="(tracker$ | async) ?? undefined"
        [prefillForm]="prefill$ | async"
        [trackerId]="trackerId$ | async"
      ></gep-tracker-form>
    </ng-container>
  </section>
</main>
