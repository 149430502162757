import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { OptionTransformers } from '../../../../transformers/option-transformers';

@Component({
  styleUrls: ['./year-filter.component.scss'],
  templateUrl: './year-filter.component.html',
})
export class YearFilterComponent implements IFilterAngularComp {
  params!: IFilterParams;
  selectedYear: number = new Date().getFullYear();

  years = Array.from(
    { length: 13 },
    (_, k) => new Date().getFullYear() - 2 + k
  ); // get last 2 years, the current and the next 10 years

  agInit(params: IFilterParams): void {
    this.params = params;
    this.setModel({ selectedYear: this.selectedYear });
  }

  isFilterActive(): boolean {
    return this.selectedYear !== null && this.selectedYear !== undefined;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return params.data.year == this.selectedYear;
  }

  getModel() {
    return { selectedYear: this.selectedYear };
  }

  setModel(model: any) {
    if (model && model.selectedYear) {
      this.selectedYear = model.selectedYear;
      this.updateFilter();
    }
  }

  updateFilter() {
    this.params.filterChangedCallback();
  }

  protected readonly OptionTransformers = OptionTransformers;
}
