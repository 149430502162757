import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Style } from '../../modules/gep-controls/models/style';
@Component({
  selector: 'gep-tracker-navbar',
  templateUrl: './tracker-navbar.component.html',
  styleUrls: ['./tracker-navbar.component.scss'],
})
export class TrackerNavbarComponent {
  protected readonly Style = Style;
  currentUrl: string = '';

  constructor(protected router: Router) {
    this.currentUrl = this.router.url;
  }
}
