<gep-settings-tab-header></gep-settings-tab-header>

<main class="w-100">
    <h2>{{ 'settings.counterpartUsers' | translate }}</h2>
    <div>
        <gep-ag-grid-table
            [rowData$]="counterpartUsers$"
            [colDefs]="colDefs"
            [sideBar]="false"
        ></gep-ag-grid-table>
    </div>
</main>
