import { Component, Input } from '@angular/core';

@Component({
  selector: 'gep-product-specs',
  templateUrl: './product-specs.component.html',
  styleUrls: ['./product-specs.component.scss'],
})
export class ProductSpecsComponent {
  @Input()
  origin?: string = '';

  @Input()
  energySource?: string = '';

  @Input()
  systemAge?: string = '';
}
