<div class="product-specs">
  <div class="spec" [class.filled]="origin !== 'keine Spezifikation'">
    <eon-ui-icon
      size="small"
      [scheme]="origin !== 'keine Spezifikation' ? 'neutral100' : 'turquoise'"
      name="location"></eon-ui-icon>
    <span>{{origin}}</span>
  </div>
  <div class="spec" [class.filled]="energySource !== 'keine Spezifikation'">
    <eon-ui-icon
      size="small"
      [scheme]="energySource !== 'keine Spezifikation' ? 'neutral100' : 'turquoise'"
      name="electricity"
    ></eon-ui-icon>
    <span>{{ energySource }}</span>
  </div>
  <div class="spec" [class.filled]="systemAge !== 'keine Spezifikation'">
    <eon-ui-icon
      size="small"
      name="clock"
      [scheme]="systemAge !== 'keine Spezifikation' ? 'neutral100' : 'turquoise'"
    ></eon-ui-icon>
    <span>{{ systemAge }}</span>
  </div>
</div>
