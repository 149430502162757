<gep-tracker-navbar></gep-tracker-navbar>

<main>
  <section [formGroup]="formGroup">
    <gep-dropdown-deprecated
      *ngIf="holidayCalendars$ | async as holidayCalendars"
      [labelOutside]="true"
      [label]="'trackerPage.calendarPage.availableCalendars' | translate"
      [value]="'EEX-Calendar'"
      formControlName="calendarName"
    >
      <gep-dropdown-option
        *ngFor="let holidayCalendar of holidayCalendars"
        [value]="holidayCalendar.calendarName"
        [label]="holidayCalendar.calendarName"
      ></gep-dropdown-option>
    </gep-dropdown-deprecated>
    <div style="width: 100%; margin-top: 100px;">
      <gep-ag-grid-table
        [gridOptions]="gridOptions"
        [colDefs]="colDefs"
        [defaultColDefConfig]="defaultColDef"
        [rowData$]="rowData$"
        [sideBar]="false"
        [getRowStyle]="getRowStyle"
        [pagination]="false"
        height="350px"
      ></gep-ag-grid-table>
    </div>
  </section>
</main>
