
<div class="d-flex justify-content-start">
  <eon-ui-button
    *ngIf="row &&
      (row?.status === STATUS.CREATED || row?.status === STATUS.PRICE_COLLECTION)
    "
    icon="info_secondary"
    class="action-buttons"
    scheme="neutral100"
    size="small"
    (click)="showInformModal(row)"
  >
  </eon-ui-button>

  <span *ngIf="row && row?.status === STATUS.BINDING">
    <eon-ui-button
      class="action-buttons"
      icon="value_proposition"
      scheme="neutral100"
      size="small"
      (click)="showCloseDealModal(row)"
    >
    </eon-ui-button>

    <eon-ui-button
      icon="copy"
      class="action-buttons"
      scheme="neutral100"
      size="small"
      (click)="cloneDeal(row)"
    >
    </eon-ui-button>

    <eon-ui-button
      class="action-buttons"
      icon="rewind"
      scheme="neutral100"
      size="small"
      (click)="resetToPriceCollection(row)"
    >
    </eon-ui-button>
  </span>

  <eon-ui-button
    *ngIf="row"
    class="action-buttons"
    scheme="neutral100"
    size="small"
    icon="edit"
    (click)="edit(row)"
  >
  </eon-ui-button>

  <eon-ui-button
    *ngIf="row && row.status !== STATUS.CLOSED && row.status !== STATUS.CANCELLED"
    class="action-buttons"
    scheme="neutral100"
    size="small"
    icon="close"
    (click)="cancelDeal(row)"
  ></eon-ui-button>
</div>
