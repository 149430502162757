import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TrackerModel } from '../../../../models/tracker.model';
import { TrackerService } from '../../../../services/tracker/tracker.service';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'gep-tracker-active-renderer',
  templateUrl: './tracker-active-renderer.component.html',
})
export class TrackerActiveRendererComponent
  implements ICellRendererAngularComp
{
  constructor(
    private trackerService: TrackerService,
    private toastService: ToastService
  ) {}

  iconName: string = 'checkbox';
  params?: ICellRendererParams;
  row?: TrackerModel;
  modalVisible = false;

  newValue: boolean = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.iconName = params.value ? 'checkbox_checked' : 'checkbox';
    this.row = params.node.data;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  showModal() {
    this.newValue =
      this.params!.value === undefined ? true : !this.params!.value;
    this.modalVisible = true;
  }

  toggleTrackerActivation() {
    this.params!.value = this.newValue;
    this.row!.active = this.newValue;

    this.trackerService.saveTracker$(this.row!).subscribe(_ => {
      this.modalVisible = false;
      this.toastService.showSavedToast();
    });
  }
}
