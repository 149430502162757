import { Component } from '@angular/core';
import { TrackerService } from '../../../../services/tracker/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ColDef, GetRowIdParams, GridOptions } from 'ag-grid-community';
import { TrackerSlice } from '../../../../models/tracker-slice.model';
import { ColumnDefinitionService } from '../../../../modules/ag-grid/column-definition.service';

@Component({
  selector: 'gep-preview-slices-page',
  templateUrl: './preview-slices-page.component.html',
  styleUrls: ['./preview-slices-page.component.scss'],
})
export class PreviewSlicesPageComponent {
  constructor(
    private trackersService: TrackerService,
    private translateService: TranslateService,
    private columnDefinitionService: ColumnDefinitionService
  ) {}

  readonly slices$: Observable<TrackerSlice[]> =
    this.trackersService.getAllSlices$();

  gridOptions: GridOptions = {
    getRowId: (params: GetRowIdParams<TrackerSlice, string>) => {
      return params.data.name! + params.data.conversionDate;
    },
  };

  colDefs: ColDef<TrackerSlice>[] = [
    this.columnDefinitionService.trackerNameColumn(true, undefined, true),
    this.columnDefinitionService.conversionDateColumn(false, 250),
    this.columnDefinitionService.segmentColumn(true),
    this.columnDefinitionService.vgColumn(true),
    {
      field: 'productKey', // todo: use consistent field name: product_key
      headerName: this.translateService.instant('product'),
      filter: 'agSetColumnFilter',
      enableRowGroup: true,
      flex: 1,
      minWidth: 150,
    },
    this.columnDefinitionService.yearColumn(),
    this.columnDefinitionService.sliceSizeColumn(),
  ];
}
