import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Style } from '../../models/style';
import { Icon } from '../../models/icon';
import { ButtonSize } from '../../models/button-size';

@Component({
  selector: 'gep-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input()
  type: 'button' | 'reset' | 'submit' = 'submit';

  @Input()
  style: Style = Style.Standard;

  @Input()
  icon?: Icon;

  @Input()
  size = ButtonSize.Normal;

  @Input()
  label?: string;

  @Input()
  disabled: boolean = false;

  @Input()
  fullWidth: boolean = false;

  @Input()
  openModalId?: string;

  @Output()
  clicked = new EventEmitter<void>();

  constructor() {}

  onButtonClick() {
    this.clicked.emit();
  }
}
