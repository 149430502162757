<eon-ui-navigation-meta slot="meta">
  <eon-ui-navigation-column contentAlignment="center" contentAlignmentMobile="center" grow="true" growMobile="true">
    <eon-ui-navigation-meta-link
      [text]="'trackerPage.navigation.masterData' | translate"
      [routerLink]="'/tracker/masterdata'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/tracker/masterdata'"
    ></eon-ui-navigation-meta-link>
    <eon-ui-navigation-meta-link
      [text]="'trackerPage.navigation.preview' | translate"
      [routerLink]="'/tracker/preview'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/tracker/preview'"
    ></eon-ui-navigation-meta-link>
    <eon-ui-navigation-meta-link
      [text]="'trackerPage.navigation.calendar' | translate"
      [routerLink]="'/tracker/calendar'"
      [routerLinkActive]="'active'"
      [current]="router.url === '/tracker/calendar'"
    ></eon-ui-navigation-meta-link>
  </eon-ui-navigation-column>
</eon-ui-navigation-meta>
