import { map, take, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, ViewChild } from '@angular/core';

import { DealService } from '../../services/deal.service';
import { DealModel, Status } from '../../models/deal.model';
import { OfferModel } from '../../models/offer.model';
import { CounterpartService } from '../../services/counterpart.service';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
} from 'rxjs';
import { ModalWithData } from '../../models/modal-with-data.model';
import { Style } from '../../modules/gep-controls/models/style';
import { Icon } from '../../modules/gep-controls/models/icon';
import { ButtonSize } from '../../modules/gep-controls/models/button-size';
import { TreeNode } from '../../models/tree-node.model';
import { CheckboxTreeComponent } from '../../modules/gep-forms/components/checkbox-tree/checkbox-tree.component';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from 'ag-grid-community';
import { ColumnDefinitionService } from '../../modules/ag-grid/column-definition.service';
import { OffersActionsRendererComponent } from '../../modules/ag-grid/renderer/offers-actions-renderer/offers-actions-renderer.component';
import { BestPriceRendererComponent } from '../../modules/ag-grid/renderer/best-price-renderer/best-price-renderer.component';
import { createTree, sortOffers } from './offers-page.functions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gep-offers-page',
  templateUrl: './offers-page.component.html',
  styleUrls: ['./offers-page.component.scss'],
})
export class OffersPageComponent implements OnDestroy {
  constructor(
    private dealService: DealService,
    private router: Router,
    private columnDefinitions: ColumnDefinitionService,
    private counterpartService: CounterpartService,
    private translateService: TranslateService
  ) {}

  @ViewChild('gepCheckboxTree')
  public checkboxTree!: CheckboxTreeComponent;

  countCounterpartsToBeInformed$ = new ReplaySubject<number>();
  private readonly confirmCancellation$ = new Subject<boolean>();

  readonly showConfirmModal$ = new BehaviorSubject(false);

  dealModal: ModalWithData = new ModalWithData();

  public informModal: {
    visible: boolean;
    counterpartUsersTree: TreeNode[];
    deal?: DealModel;
    selectAll: boolean;
    disableSendButton: boolean;
  } = {
    visible: false,
    counterpartUsersTree: [],
    selectAll: false,
    disableSendButton: false,
  };

  public offersModal: {
    visible: boolean;
    bindingSuccessful: boolean | null;
    loading: boolean | null;
    offers?: OfferModel[];
    deal?: DealModel;
  } = {
    visible: false,
    bindingSuccessful: null,
    loading: null,
  };

  readonly STATUS = Status;
  protected readonly Icon = Icon;
  protected readonly ButtonSize = ButtonSize;
  protected readonly Style = Style;

  readonly _destroy$ = new Subject<void>();
  readonly reloadOffers$ = new Subject<void>();

  api!: GridApi;

  offersData$: Observable<DealModel[]> = this.reloadOffers$.pipe(
    switchMap(() =>
      this.dealService.getDealsOffers(0, 2000, {}, []).pipe(
        map(p => {
          p.data = p.data.map(row => {
            if (row.binding_period_end && row.binding_period_start) {
              row.binding_period_end = new Date(row.binding_period_end);
              row.binding_period_end.toLocaleString();

              row.binding_period_start = new Date(row.binding_period_start);
              row.binding_period_start.toLocaleString();
            }

            row.offers?.map((offer: OfferModel) => {
              offer.bound_date &&
                (offer.bound_date = new Date(offer.bound_date));
              offer.bound_date && offer.bound_date.toLocaleString();

              offer.created_at = new Date(offer.created_at);
              offer.created_at.toLocaleString();

              offer.updated_at &&
                (offer.updated_at = new Date(offer.updated_at));
              offer.updated_at && offer.updated_at.toLocaleString();
              return offer;
            });

            return Object.assign(new DealModel(), row);
          });

          return p;
        }),
        map(x => x.data)
      )
    )
  );

  gridOptions: GridOptions = {
    context: {
      componentParent: this,
    },
    rowHeight: 48,
  };

  colDef: ColDef<DealModel>[] = [
    this.columnDefinitions.dealIdColumn(true),
    this.columnDefinitions.idColumn(true),
    this.columnDefinitions.dealTypeColumn(true),
    this.columnDefinitions.productKeyColumn(),
    this.columnDefinitions.bindingPeriodStartColumn(),
    this.columnDefinitions.bindingPeriodEndColumn(),
    this.columnDefinitions.customerColumn(),
    this.columnDefinitions.contactPersonColumn(true),
    this.columnDefinitions.yearColumn(),
    this.columnDefinitions.quantityInMWhColumn(),
    this.columnDefinitions.quantityInTonnsColumn(),
    this.columnDefinitions.vgColumn(true),
    this.columnDefinitions.regionColumn(true),
    this.columnDefinitions.commentColumn(true),
    this.columnDefinitions.energySourceColumn(true),
    this.columnDefinitions.redistributorColumn(true),
    this.columnDefinitions.systemAgeColumn(true),
    this.columnDefinitions.statusColumn(false, 180),
    {
      headerName: this.translateService.instant('best_price'),
      cellRenderer: BestPriceRendererComponent,
      sortable: false,
    },
    {
      headerName: this.translateService.instant('product_actions'),
      cellRenderer: OffersActionsRendererComponent,
      sortable: false,
      width: 200,
    },
  ];

  onGridReady(event: GridReadyEvent) {
    this.api = event.api;
    this.reloadOffers$.next();
  }

  /*
    Inform modal
   */

  selectAllCounterparts(event: any) {
    const value = event.detail;
    this.checkboxTree.selectAll(value);
  }

  onInformModalCheckboxChange(tree: TreeNode[]) {
    this.informModal.counterpartUsersTree = tree;

    const count = tree.reduce((sum, counterPart) => {
      return (
        sum +
        (counterPart.children
          ? counterPart.children.filter(child => child.checked).length
          : 0)
      );
    }, 0);
    this.countCounterpartsToBeInformed$.next(count);

    this.informModal.disableSendButton =
      this.informModal.counterpartUsersTree.every(
        counterPartUser => !counterPartUser.checked
      ) && this.informModal.deal?.status === Status.PRICE_COLLECTION;
  }

  // keep method as it is used in offers-actions-renderer
  showInformModal(deal: DealModel): void {
    this.informModal.visible = true;
    this.informModal.deal = deal;
    this.informModal.disableSendButton =
      deal.status === Status.PRICE_COLLECTION;
    this.counterpartService
      .getCounterpartUsers()
      .pipe(takeUntil(this._destroy$))
      .subscribe(counterpartUsers => {
        const counterpartUsersSorted = counterpartUsers.sort((a, b) => {
          if (a.company_name === null || b.company_name === null) {
            return 1;
          }

          if (a.company_name === 'N\\A' || b.company_name === 'N\\A') {
            return 1;
          }

          return a.company_name.localeCompare(b.company_name);
        });

        this.informModal.counterpartUsersTree = createTree(
          counterpartUsersSorted
        );
      });
  }

  resetInformModal(): void {
    this.informModal.visible = false;
    this.informModal.counterpartUsersTree = [];
    this.informModal.deal = undefined;
  }

  /*
    Offers modal
   */

  // keep method as it is used in offers-actions-renderer
  showOffersModal(deal: DealModel): void {
    this.offersModal.visible = true;
    this.offersModal.deal = deal;
    this.offersModal.offers = sortOffers(deal);
  }

  resetOffersModal(): void {
    this.offersModal.visible = false;
    this.offersModal.bindingSuccessful = false;
    this.offersModal.offers = [];
    this.offersModal.deal = undefined;
    this.offersModal.loading = null;
  }

  notify(deal?: DealModel) {
    if (deal?.id) {
      const cps_to_be_informed: string[] = [];

      this.informModal.counterpartUsersTree.forEach(node => {
        node?.children?.forEach(child => {
          if (child.checked && child.value) {
            cps_to_be_informed.push(child.value);
          }
        });
      });

      this.dealService
        .updateDealStatus(deal.id, {
          status: Status.PRICE_COLLECTION,
          cps_to_be_informed,
        })
        .pipe(take(1))
        .subscribe(() => {
          deal.status = Status.PRICE_COLLECTION;
          this.resetInformModal();
        });
    } else {
      console.error('Invalid parameters');
    }
  }

  bind(deal?: DealModel, offer?: OfferModel) {
    if (deal?.id && offer) {
      this.offersModal.loading = true;
      this.dealService
        .updateDealStatus(deal.id, {
          status: Status.BINDING,
          counterpart_oid: offer.counterpart_oid,
        })
        .pipe(take(1))
        .subscribe({
          next: () => {
            deal.status = Status.BINDING;
            this.offersModal.bindingSuccessful = true;
            this.offersModal.loading = false;
            this.resetOffersModal();
          },
          error: () => {
            this.offersModal.bindingSuccessful = false;
            this.offersModal.loading = false;
          },
        });
    } else {
      console.error('Invalid parameters');
    }
  }

  // keep method as it is used in offers-actions-renderer
  resetToPriceCollection(deal: DealModel) {
    this.dealService
      .updateDealStatus(deal.id!, { status: Status.PRICE_COLLECTION })
      .pipe(
        map(() => (deal.status = Status.PRICE_COLLECTION)),
        map(() => this.reloadOffers$.next()),
        take(1)
      )
      .subscribe();
  }

  // keep method as it is used in offers-actions-renderer
  cloneDeal(deal: DealModel) {
    this.dealService
      .cloneDeal(deal)
      .pipe(take(1))
      .subscribe(newDealId =>
        this.router.navigateByUrl(`/enquiry/${newDealId.doc_id}`)
      );
  }

  // keep method as it is used in offers-actions-renderer
  cancelDeal(deal: DealModel) {
    this.showConfirmModal$.next(true);
    this.confirmCancellation$
      .pipe(
        switchMap(isConfirmed => {
          if (isConfirmed) {
            return this.dealService
              .updateDealStatus(deal.id!, {
                status: Status.CANCELLED,
              })
              .pipe(map(() => (deal.status = Status.CANCELLED)));
          }

          return of(null);
        }),
        take(1)
      )
      .subscribe();
  }

  onConfirm(isConfirmed: boolean) {
    this.confirmCancellation$.next(isConfirmed);
    this.showConfirmModal$.next(false);
  }

  showCloseDealModal(row: DealModel) {
    this.dealModal.deal = row;
    this.dealModal.show();
  }

  // keep method as it is used in offers-actions-renderer
  close(deal: DealModel): void {
    if (deal?.id) {
      this.dealModal.reset();
      this.dealService
        .updateDealStatus(deal.id, { status: Status.CLOSED })
        .pipe(take(1))
        .subscribe(() => {
          deal.status = Status.CLOSED;
        });
    } else {
      console.error('Invalid parameters');
    }
  }

  refreshDealsAfterManualEdit(deal: DealModel): void {
    this.resetOffersModal();
    this.reloadOffers$.next();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
