<ng-container *ngIf="form$ | async as form">
  <ng-container *ngIf="productConfigurations$ | async as productConfigurations">
    <form [formGroup]="form">
      <h2>{{ 'trackerPage.trackerForm.orderer' | translate }}</h2>
      <fieldset>
        <gep-input
          [label]="'trackerPage.trackerForm.kamName' | translate"
          formControlName="name"
        ></gep-input>
        <gep-input
          [hidden]="true"
          formControlName="customer"
          value="ESG"
        ></gep-input>
      </fieldset>
      <fieldset>
        <gep-dropdown
          [label]="'enquiryForm.salesCompany' | translate"
          formControlName="vg"
          [options]="(salesCompanies$ | async)!"
          [optionLabelTransformer]="OptionTransformers.identityStringTransformer"
          [optionValueTransformer]="OptionTransformers.identityStringTransformer"
        ></gep-dropdown>
        <gep-dropdown
          [label]="'enquiryForm.segment' | translate"
          formControlName="segment"
          [options]="(segments$ | async)!"
          [optionValueTransformer]="OptionTransformers.identityStringTransformer"
          [optionLabelTransformer]="OptionTransformers.identityStringTransformer"
        ></gep-dropdown>
      </fieldset>


      <h2>{{ 'trackerPage.trackerForm.dealDetails' | translate }}</h2>
      <fieldset>
        <gep-dropdown
          [label]="'product' | translate"
          [options]="(productOptions$ | async)!"
          [optionValueTransformer]="OptionTransformers.transformStringOptions"
          [optionLabelTransformer]="OptionTransformers.transformStringOptions"
          formControlName="productKey">
        </gep-dropdown>
        <gep-product-specs
          *ngIf="selectedProduct$ | async as selectedProduct; else emptyField"
          [origin]="selectedProduct.region_id ?? ''"
          [systemAge]="selectedProduct.system_age ?? ''"
          [energySource]="selectedProduct.energy_source ?? ''"
        ></gep-product-specs>

        <ng-template #emptyField><span></span></ng-template>
      </fieldset>
      <fieldset>
        <gep-dropdown
          formControlName="dealType"
          [options]="dealTypes"
          [optionLabelTransformer]="OptionTransformers.identityStringTransformer"
          [optionValueTransformer]="OptionTransformers.identityStringTransformer"
          [label]="'settings.trackerDefaults.dealType' | translate"
        ></gep-dropdown>
        <gep-dropdown
          formControlName="year"
          [options]="deliveryYears"
          [optionLabelTransformer]="OptionTransformers.identityStringTransformer"
          [optionValueTransformer]="OptionTransformers.identityStringTransformer"
          [label]="'year' | translate"
        ></gep-dropdown>

      </fieldset>

      <fieldset>
        <gep-input
          [label]="'deal_id' | translate"
          formControlName="psiId"
        ></gep-input>
        <div></div>
      </fieldset>

      <h2>{{ 'trackerPage.trackerForm.sourcingPeriod' | translate }}</h2>
      <fieldset>
        <gep-date-picker
          [label]="'trackerPage.sourcingStart' | translate"
          [minimumDate]="sourcingStartMinimumDate$ | async"
          [disabled]="(sourcingStartDisabled$ | async) || false"
          formControlName="sourcingStart"
        ></gep-date-picker>
        <gep-date-picker
          [label]="'trackerPage.sourcingEnd' | translate"
          [minimumDate]="tomorrow"
          formControlName="sourcingEnd"
        ></gep-date-picker>
      </fieldset>
      <fieldset>
        <gep-number-input
          [label]="'trackerPage.trackerForm.totalQuantity' | translate"
          formControlName="totalQuantity"
          [min]="0"
        ></gep-number-input>
      </fieldset>

      <h2>{{ 'trackerPage.scheduling' | translate }}</h2>
      <fieldset>
        <gep-dropdown
          [options]="schedulingTypes"
          [optionLabelTransformer]="OptionTransformers.transformStringOptions"
          [optionValueTransformer]="OptionTransformers.transformStringOptions"
          [label]="'trackerPage.trackerForm.schedulingTypes' | translate"
          formControlName="scheduleType"
        ></gep-dropdown>
        <gep-dropdown
          *ngIf="showDayOfWeek$ | async"
          [label]="'trackerPage.trackerForm.dayOfWeek' | translate"
          [options]="daysOfWeek"
          [optionLabelTransformer]="OptionTransformers.identityStringTransformer"
          [optionValueTransformer]="OptionTransformers.identityStringTransformer"
          formControlName="scheduledDay"
        ></gep-dropdown>
        <gep-dropdown
          *ngIf="showDayOfMonth$ | async"
          [options]="daysOfMonth"
          [optionLabelTransformer]="OptionTransformers.transformStringOptions"
          [label]="'trackerPage.trackerForm.dayOfMonth' | translate"
          formControlName="scheduledDayOfMonth"
        ></gep-dropdown>
        <!--
        <gep-dropdown
          [label]="'trackerPage.executionTime' | translate"
          [options]="times"
          [optionValueTransformer]="OptionTransformers.transformStringOptions"
          [optionLabelTransformer]="OptionTransformers.transformStringOptions"
          formControlName="schedulingTime"
        ></gep-dropdown>
        -->
      </fieldset>
      <fieldset>
        <gep-dropdown-deprecated
          *ngIf="holidayCalendars$ | async as holidayCalendars"
          class="gep-dropdown"
          formControlName="holidayCalendarName"
          [label]="'trackerPage.trackerForm.holidayCalendar' | translate"
          [labelOutside]="true"
        >
          <div class="counterPartDropDown">
            <gep-dropdown-option
              *ngFor="let holidayCalendar of holidayCalendars"
              [value]="holidayCalendar.calendarName"
              [label]="holidayCalendar.calendarName"
            >
            </gep-dropdown-option>
          </div>
        </gep-dropdown-deprecated>
        <div></div>
      </fieldset>

      <div *ngIf="form$ | async as form" class="mt-md-4">
        <div style="padding-left: 12px;" class="d-flex flex-column">
          <div *ngIf="form.errors?.dateRangeEndBeforeStart" class="gep-alert gep-alert-danger">{{'errors.dateRangeEndBeforeStart' | translate }}</div>
          <div *ngIf="form.errors?.totalQuantitySmallerThanClosedQuantityInvalid" class="gep-alert gep-alert-danger">{{'errors.totalQuantitySmallerThanClosedQuantityInvalid' | translate : {value: form.get('closedQuantity')?.value} }}</div>
        </div>
      </div>

      <gep-tracker-summary
        *ngIf="trackerPreviewResult$ | async as trackerSummary"
        [trackerSummary]="trackerSummary"
      ></gep-tracker-summary>

      <div class="form-buttons">
        <gep-button
          [label]="'createEnquiry.saveButton' | translate"
          type="button"
          [disabled]="!form.valid"
          (clicked)="saveTracker(form.value)"
        >
        </gep-button>
      </div>
    </form>
  </ng-container>
</ng-container>
