export const environment = {
  production: true,
  environment: 'qa',
  API: 'https://backend.qa.gep.eon.com/',
  aadClientId: 'e9202777-bc45-495b-9d87-d5e6f3698252',
  aadAuthority:
    'https://login.microsoftonline.com/36630e33-ef9b-4ed1-a948-6f85770a5636/',
  aadScopes: 'api://e9202777-bc45-495b-9d87-d5e6f3698252/user_impersonation',
  aadRedirectUri: 'https://qa.gep.eon.com',
  aadProtectedUri: [
    'https://backend.qa.gep.eon.com/v1/**',
    'https://backend.qa.gep.eon.com/acs/**',
  ],
  sentryConfig: {
    environment: 'qa',
    release: '2.20.0', // todo: replace build-tag automatically
    dsn: 'https://121e66f4ad428ce18aecfd47280534ce@sentry.eon.com/179',
    shouldShowModal: false,
    enabled: true,
  },
  featureFlagsEnabled: true,
  appConfigurationConnectionString: 'Endpoint=https://gep-q-app-configuration.azconfig.io;Id=31ai;Secret=8HZCGegXoaOun21rGxkvtmLbHT5rMgV6rLqGRs6B742UgsPlPyuTJQQJ99AKAC5RqLJDb6AQAAABAZAC3jcK', // will be replaced by azure pipeline
};
