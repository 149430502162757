<gep-settings-tab-header></gep-settings-tab-header>

<main class="d-flex flex-row w-100">
  <div class="d-flex w-100">
    <div style="flex: 1;" class="h-100 me-4">
      <h2>{{ 'settings.trackerDefaults.selectTemplate' | translate }}</h2>

      <gep-select-list *ngIf="templates$ | async as templates" [options]="templates" (itemSelected)="selectTemplate($event);"></gep-select-list>

      <eon-ui-button class="mt-2" [text]="'settings.trackerDefaults.createNewTemplate' | translate"  (click)="createNewTemplate()" fullWidth="true"></eon-ui-button>
    </div>
    <div style="flex: 4; border-left: 1px solid grey;" class="position-relative">
      <ng-container *ngIf="showForm else errorNoTemplateSelected">
        <eon-ui-button class="delete-product position-absolute" [icon]="Icon.Trash" (click)="showDeleteModal()" [text]="'settings.trackerDefaults.deleteTemplate' | translate" [disabled]="isNewTemplate"></eon-ui-button>
        <h2>{{ 'settings.trackerDefaults.editTemplate' | translate }}</h2>
        <small class="d-block text-center"><i>{{'settings.mandatoryFields' | translate}}</i></small>

        <form [formGroup]="form">
          <fieldset>
            <gep-input [label]="'settings.trackerDefaults.trackerName' | translate"  [labelOutside]="true" [requiredStar]="true" formControlName="name"></gep-input>
            <gep-dropdown
              *ngIf="vgs$ | async as vgs"
              [options]="vgs"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [labelOutside]="true"
              [requiredStar]="true"
              [label]="'settings.trackerDefaults.vg' | translate"
              formControlName="vg"
            ></gep-dropdown>
          </fieldset>

          <fieldset>
            <gep-dropdown
              *ngIf="segments$ | async as segments"
              [options]="segments"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [labelOutside]="true"
              [requiredStar]="true"
              [label]="'settings.trackerDefaults.segment' | translate"
              formControlName="segment"
            ></gep-dropdown>
            <gep-dropdown
              *ngIf="products$ | async as products"
              [options]="products"
              [labelOutside]="true"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [requiredStar]="true"
              [label]="'settings.trackerDefaults.product' | translate"
              formControlName="productKey"
            ></gep-dropdown>
          </fieldset>

          <fieldset>
            <gep-dropdown
              *ngIf="years$ | async as years"
              [options]="years"
              [labelOutside]="true"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [requiredStar]="true"
              [label]="'settings.trackerDefaults.year' | translate"
              formControlName="year"
            ></gep-dropdown>
            <gep-dropdown
              [options]="dealTypes"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [labelOutside]="true"
              [requiredStar]="true"
              [label]="'settings.trackerDefaults.dealType' | translate"
              formControlName="dealType"
            ></gep-dropdown>
          </fieldset>
          <fieldset>
            <gep-number-input [label]="'trackerPage.trackerForm.totalQuantity' | translate" [labelOutside]="true" formControlName="totalQuantity"></gep-number-input>
            <gep-input [label]="'deal_id' | translate"  [labelOutside]="true" [requiredStar]="true" formControlName="psiId"></gep-input>
          </fieldset>

          <fieldset>
            <gep-dropdown
              [options]="scheduleTypes"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [labelOutside]="true"
              [requiredStar]="true"
              [label]="'settings.trackerDefaults.schedulingType' | translate"
              formControlName="scheduleType"
            ></gep-dropdown>
            <gep-dropdown
              *ngIf="showDayOfWeek$ | async"
              [options]="scheduledDays"
              [optionValueTransformer]="OptionTransformers.transformStringOptions"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [labelOutside]="true"
              [requiredStar]="true"
              [label]="'settings.trackerDefaults.scheduledDay' | translate"
              formControlName="scheduledDay"
            ></gep-dropdown>
            <gep-dropdown
              *ngIf="showDayOfMonth$ | async"
              [options]="daysOfMonth"
              [labelOutside]="true"
              [requiredStar]="true"
              [optionLabelTransformer]="OptionTransformers.transformStringOptions"
              [label]="'trackerPage.trackerForm.dayOfMonth' | translate"
              formControlName="scheduledDayOfMonth"
            ></gep-dropdown>
          </fieldset>

          <fieldset>
            <gep-dropdown-deprecated
              *ngIf="holidayCalendars$ | async as holidayCalendars"
              class="gep-dropdown"
              formControlName="holidayCalendarName"
              [label]="'trackerPage.trackerForm.holidayCalendar' | translate"
              [labelOutside]="true"
            >
              <div class="counterPartDropDown">
                <gep-dropdown-option
                  *ngFor="let holidayCalendar of holidayCalendars"
                  [value]="holidayCalendar.calendarName"
                  [label]="holidayCalendar.calendarName"
                >
                </gep-dropdown-option>
              </div>
            </gep-dropdown-deprecated>
            <div></div>
          </fieldset>

          <div class="text-center">
            <eon-ui-button [text]="'settings.save' | translate" [disabled]="!this.form.valid"  (click)="saveTemplate()"></eon-ui-button>
          </div>

        </form>
      </ng-container>

      <ng-template #errorNoTemplateSelected>
        <p style="margin: auto; width: 100%; text-align: center;">{{'settings.trackerDefaults.noTemplateSelected' | translate}}</p>
      </ng-template>

      <gep-modal
        [visible]="deleteModalVisible"
        [headline]="'settings.trackerDefaults.deleteModal.headline' | translate"
        [description]="'settings.trackerDefaults.deleteModal.question' | translate"
        [positiveButtonStyle]="Style.Highlight"
        [closeButtonText]="'no' | translate"
        [positiveButtonText]="'yes' | translate"
        (positiveButtonClicked)="deleteTemplate()"
        (closedButtonClicked)="deleteModalVisible = false;"
        [showClosingX]="true"
        modalId="delete-template-modal"
      ></gep-modal>
    </div>
  </div>
</main>
