import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  HolidayCalendar,
  HolidayCalendarEntry,
} from '../../models/holiday-calendar-entry.model';
import { TrackerModel } from '../../models/tracker.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HolidayCalendarService {
  constructor(private http: HttpClient) {}

  getHolidayCalendars$(): Observable<HolidayCalendar[]> {
    return this.http.get<HolidayCalendar[]>(
      `${environment.API}v1/tracker/calendars`
    );
  }

  getHolidayCalendarEntries$(name: string): Observable<HolidayCalendarEntry[]> {
    return this.http.get<TrackerModel[]>(
      `${environment.API}v1/tracker/calendars/${name}`
    );
  }
}
