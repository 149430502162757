import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { DealModel } from '../../../../models/deal.model';
import { ButtonSize } from '../../../gep-controls/models/button-size';
import { ModalWithData } from '../../../../models/modal-with-data.model';
import { Style } from '../../../gep-controls/models/style';
import { take, tap } from 'rxjs/operators';
import { finalize } from 'rxjs';
import { ToastService } from '../../../../services/toast.service';
import { DealService } from '../../../../services/deal.service';

@Component({
  selector: 'gep-consignments-actions-renderer',
  styleUrls: ['./consignments-actions-renderer.component.scss'],
  templateUrl: './consignments-actions-renderer.component.html',
})
export class ConsignmentsActionsRendererComponent
  implements ICellRendererAngularComp
{
  protected readonly ButtonSize = ButtonSize;
  protected readonly Style = Style;

  row?: DealModel;
  confirmOneModal: ModalWithData = new ModalWithData();
  submitting: boolean = false;
  gridApi?: GridApi;
  rowIndex: number | null = null;

  constructor(
    private toastService: ToastService,
    private dealService: DealService
  ) {}

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.row = params.node.data;
    this.gridApi = params.api;
    this.rowIndex = params.node.rowIndex;
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  checkClicked(checked: boolean) {
    this.row!.quantity_delivery_confirmed = Boolean(checked);
    this.row!.quantity_delivery_date = checked ? new Date() : undefined;
    this.row!.quantity_delivery_subaccount = checked
      ? this.row!.product_key
      : '';
  }

  openConfirmModal() {
    this.confirmOneModal.show();
  }

  saveCheck(): void {
    if (!this.row) {
      return;
    }

    this.submitting = true;
    this.confirmOneModal.close();
    this.dealService
      .updateDealConsignment(this.row.id!, {
        quantity_delivery_confirmed: this.row.quantity_delivery_confirmed,
        quantity_delivery_date: this.row.quantity_delivery_date,
        quantity_delivery_subaccount: this.row.quantity_delivery_subaccount,
        redistributor: this.row.redistributor,
      })
      .pipe(
        take(1),
        tap(_ => {
          this.toastService.showSavedToast();
          this.gridApi!.applyTransaction({
            remove: [this.row],
          });
        }),
        finalize(() => {
          this.submitting = false;
        })
      )
      .subscribe();
  }
}
