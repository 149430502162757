import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, of, startWith } from 'rxjs';
import { Icon } from '../../../../modules/gep-controls/models/icon';
import { ProductService } from '../../../../services/product.service';
import { ProductConfigModel } from '../../../../models/product-config.model';
import { TrackerDefaultsService } from '../../../../services/tracker/tracker-defaults.service';
import { OptionTransformers } from '../../../../transformers/option-transformers';
import { TrackerModel } from '../../../../models/tracker.model';
import { map, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { TrackerService } from '../../../../services/tracker/tracker.service';

@Component({
  selector: 'gep-edit-tracker-page',
  templateUrl: './edit-tracker-page.component.html',
  styleUrls: ['./edit-tracker-page.component.scss'],
})
export class EditTrackerPageComponent implements OnInit {
  readonly prefillDropdownControl = new UntypedFormControl(null);

  readonly prefill$: Observable<TrackerModel> =
    this.prefillDropdownControl.valueChanges;
  readonly prefillOptions$ = this.trackerDefaultsService
    .getTrackerDefaults$()
    .pipe(
      map(trackerDefaults =>
        trackerDefaults.map(item => ({
          ...item,
          id: null,
        }))
      )
    );

  readonly productConfigurations$: Observable<ProductConfigModel[]> =
    this.productService.getProductConfigurations$();

  trackerId$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  protected readonly Icon = Icon;
  protected readonly OptionTransformers = OptionTransformers;

  constructor(
    private productService: ProductService,
    private trackerService: TrackerService,
    private trackerDefaultsService: TrackerDefaultsService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const trackerId = this.activatedRoute.snapshot.paramMap.get('id');
    if (trackerId) {
      this.trackerId$.next(trackerId);
    }
  }

  readonly tracker$: Observable<TrackerModel | undefined> =
    this.trackerId$.pipe(
      startWith(undefined),
      switchMap(dealId => {
        if (dealId) {
          return this.trackerService.getTrackerById$(dealId);
        }
        return of(undefined);
      })
    );
}
