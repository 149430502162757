import { Component, OnInit } from '@angular/core';
import { Observable, startWith, Subject } from 'rxjs';
import { LabelValueModel } from '../../../../models/label-value.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ProductService } from '../../../../services/product.service';
import { ToastService } from '../../../../services/toast.service';
import { OptionTransformers } from '../../../../transformers/option-transformers';
import {
  TRACKER_DEAL_TYPES,
  SCHEDULE_TYPES,
  SCHEDULED_DAYS,
} from '../../../../models/tracker.model';
import { HolidayCalendarService } from '../../../../services/tracker/holiday-calendar.service';
import { HolidayCalendar } from '../../../../models/holiday-calendar-entry.model';
import { TrackerDefaultsService } from '../../../../services/tracker/tracker-defaults.service';
import { Icon } from '../../../gep-controls/models/icon';
import { Style } from '../../../gep-controls/models/style';
import { setValidatorsForDayFields } from '../../../tracker/utils/tracker-form.functions';

@Component({
  selector: 'gep-tracker-defaults-page',
  templateUrl: './tracker-defaults-page.component.html',
  styleUrls: ['./tracker-defaults-page.component.scss'],
})
export class TrackerDefaultsPageComponent implements OnInit {
  private trackerDefaultListChangedSubject = new Subject<void>();
  trackerDefaultListChanged$ =
    this.trackerDefaultListChangedSubject.asObservable();

  templates$!: Observable<LabelValueModel[]>;

  form: FormGroup = new FormGroup<any>({
    name: new FormControl(undefined, [Validators.required]),
    dealType: new FormControl(undefined, [Validators.required]),
    vg: new FormControl(undefined, [Validators.required]),
    productKey: new FormControl(undefined, [Validators.required]),
    segment: new FormControl(undefined, [Validators.required]),
    year: new FormControl(undefined, [Validators.required]),
    totalQuantity: new FormControl(undefined, [Validators.required]),
    scheduleType: new FormControl(undefined, [Validators.required]),
    scheduledDay: new FormControl(undefined),
    scheduledDayOfMonth: new FormControl(undefined),
    psiId: new FormControl(undefined, [Validators.required]),
    holidayCalendarName: new FormControl(undefined, [Validators.required]),
    id: new FormControl(),
  });

  dealTypes: string[] = Object.values(TRACKER_DEAL_TYPES);
  readonly scheduleTypes: string[] = Object.values(SCHEDULE_TYPES);
  readonly scheduledDays: string[] = Object.values(SCHEDULED_DAYS);
  readonly daysOfMonth: number[] = Array.from({ length: 28 }, (_, i) => i + 1);

  segments$: Observable<string[]> = this.productService.getSegments$();
  vgs$: Observable<string[]> = this.productService.getVgs$();
  products$: Observable<string[]> = this.productService
    .getProductConfigurations$()
    .pipe(map(x => x.map(y => y.key)));
  years$: Observable<string[]> = this.productService.getYears$();
  holidayCalendars$: Observable<HolidayCalendar[]> =
    this.holidayCalendarService.getHolidayCalendars$();

  showForm: boolean = false;
  isNewTemplate: boolean = false;
  deleteModalVisible: boolean = false;

  protected readonly Icon = Icon;
  protected readonly Style = Style;
  protected readonly OptionTransformers = OptionTransformers;

  constructor(
    protected productService: ProductService,
    private trackerDefaultsService: TrackerDefaultsService,
    private toastService: ToastService,
    private holidayCalendarService: HolidayCalendarService
  ) {}

  ngOnInit(): void {
    this.templates$ = this.trackerDefaultListChanged$.pipe(
      startWith(null),
      switchMap(() =>
        this.trackerDefaultsService.getTrackerDefaultsForLabelValue$()
      )
    );
  }

  createNewTemplate() {
    this.showForm = true;
    this.isNewTemplate = true;
    this.form.reset();
  }

  showDeleteModal() {
    this.deleteModalVisible = true;
  }

  deleteTemplate() {
    this.trackerDefaultsService
      .deleteTrackerDefault$(<string>this.form.get('id')?.value)
      .pipe(take(1))
      .subscribe(_ => {
        this.deleteModalVisible = false;
        this.resetView();
        this.trackerDefaultListChangedSubject.next();
      });
  }

  saveTemplate() {
    this.trackerDefaultsService
      .saveTrackerDefault$(this.form.value)
      .pipe(take(1))
      .subscribe(_ => {
        this.toastService.showSavedToast();
        if (this.isNewTemplate) {
          this.resetView();
        }
        this.trackerDefaultListChangedSubject.next();
      });
  }

  selectTemplate(templateName: string) {
    this.showForm = true;
    this.isNewTemplate = false;
    this.form.reset();
    this.trackerDefaultsService
      .getTrackerDefaultsById$(templateName)
      .pipe(take(1))
      .subscribe(data => {
        if (data) {
          this.form.patchValue(data);
        }
      });
  }

  resetView() {
    this.form.reset();
    this.showForm = false;
  }

  readonly showDayOfWeek$: Observable<boolean> = this.form
    .get('scheduleType')!
    .valueChanges.pipe(
      tap(schedulingType =>
        setValidatorsForDayFields(this.form, schedulingType)
      ),
      map(schedulingType => schedulingType === SCHEDULE_TYPES.WEEKLY)
    );

  readonly showDayOfMonth$: Observable<boolean> = this.form
    .get('scheduleType')!
    .valueChanges.pipe(
      tap(schedulingType =>
        setValidatorsForDayFields(this.form, schedulingType)
      ),
      map(schedulingType => schedulingType === SCHEDULE_TYPES.MONTHLY)
    );
}
