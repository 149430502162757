import { Component } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { TrackerService } from '../../../../services/tracker/tracker.service';
import { TrackerModel } from '../../../../models/tracker.model';
import { ButtonSize } from '../../../../modules/gep-controls/models/button-size';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ColumnDefinitionService } from '../../../../modules/ag-grid/column-definition.service';

@Component({
  selector: 'gep-master-data-page',
  templateUrl: './master-data-page.component.html',
  styleUrls: ['./master-data-page.component.scss'],
})
export class MasterDataPageComponent {
  protected readonly ButtonSize = ButtonSize;

  constructor(
    private trackersService: TrackerService,
    private translateService: TranslateService,
    private columnDefinitionService: ColumnDefinitionService
  ) {}

  readonly trackers$: Observable<TrackerModel[]> =
    this.trackersService.getTrackers$();

  colDefs: ColDef<TrackerModel>[] = [
    this.columnDefinitionService.trackerNameColumn(),
    this.columnDefinitionService.vgColumn(),
    this.columnDefinitionService.segmentColumn(true),
    {
      field: 'productKey',
      headerName: this.translateService.instant('product'),
      filter: 'agSetColumnFilter',
      enableRowGroup: true,
    },
    this.columnDefinitionService.yearColumn(),
    this.columnDefinitionService.sourcingStartColumn(false, 180),
    this.columnDefinitionService.sourcingEndColumn(false, 180),
    this.columnDefinitionService.totalQuantityColumn(),
    this.columnDefinitionService.closedQuantityColumn(),
    this.columnDefinitionService.remainingQuantityColumn(),
    this.columnDefinitionService.scheduleTypeColumn(false, 100),
    this.columnDefinitionService.trackerActiveColumn(false, 100),
    this.columnDefinitionService.masterDataActionsColumn(false, 100),
  ];
}
