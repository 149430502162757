import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DealModel } from '../../models/deal.model';
import { DealService } from '../../services/deal.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'gep-deals-details-page',
  templateUrl: './deals-details-page.component.html',
  styleUrls: ['./deals-details-page.component.scss'],
})
export class DealsDetailsPageComponent implements OnInit {
  dealId?: string | null;
  deal?: DealModel;

  constructor(
    private dealService: DealService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dealId = params['id'];
      if (this.dealId != null) {
        this.loadDealDetails(this.dealId);
      }
    });
  }

  loadDealDetails(dealId: string) {
    this.dealService
      .getDealById(dealId)
      .pipe(take(1))
      .subscribe((fetchedDeal: DealModel | undefined) => {
        if (fetchedDeal) {
          this.deal = fetchedDeal;
        }
      });
  }

  goToEditDealPage(dealId: string | undefined) {
    if (dealId) {
      this.router.navigate(['/admin', 'edit-deal', dealId]);
    }
  }
}
