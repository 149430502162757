<div>
  <gep-button
    (clicked)="openEditTrackerPage()"
    [size]="ButtonSize.Small"
    [icon]="Icon.Edit"
  >
  </gep-button>
  <gep-button
    (clicked)="openTrackerDeleteModal()"
    [size]="ButtonSize.Small"
    [icon]="Icon.Trash"
  >
  </gep-button>
</div>

<gep-modal
  [headline]="'trackerPage.masterDataPage.deleteModal.header' | translate"
  [description]="'trackerPage.masterDataPage.deleteModal.description' | translate"
  [showClosingX]="true"
  [closeButtonText]="'no' | translate"
  [positiveButtonText]="'yes' | translate"
  [visible]="showModal"
  (closedButtonClicked)="this.showModal = false"
  (positiveButtonClicked)="deleteTracker(params)"
></gep-modal>
