import { EnquiryFormModel } from '../modules/enquiry/models/enquiry-form-model';
import { ProductConfigModel } from '../models/product-config.model';
import { TrackerModel } from '../models/tracker.model';

export class OptionTransformers {
  static transformStringOptions(option: string) {
    return option;
  }

  static identityStringTransformer(option: string): string {
    return String(option);
  }

  static transformEnquiryFormModelToContactPerson(
    prefillOption: Partial<EnquiryFormModel>
  ): string {
    return prefillOption.contact_person!;
  }

  static productConfigTransformer(productConfig: ProductConfigModel): string {
    return productConfig.key;
  }

  static trackerDefaultsLabelTransformer(tracker: TrackerModel): string {
    return tracker.name!;
  }
}
