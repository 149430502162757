import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'gep-checkbox-renderer',
  templateUrl: './checkbox-renderer.component.html',
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  iconName: string = 'checkbox';

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.iconName = params.value ? 'checkbox_checked' : 'checkbox';
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
