import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnquiryDefaultModel } from '../models/enquirydefault.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ProductConfigModel } from '../models/product-config.model';
import { LabelValueModel } from '../models/label-value.model';
import { GeneralSettingsModel } from '../models/general-settings.model';
import { runOnceUntilComplete } from '../effects/run-once.effect';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private readonly http: HttpClient) {}
  debounce = runOnceUntilComplete();
  // GET
  getGeneralSettings$(): Observable<any> {
    return this.http.get<GeneralSettingsModel>(
      `${environment.API}v1/config/settings`
    );
  }

  getEnquiryDefaults$(): Observable<LabelValueModel[]> {
    return this.http
      .get<EnquiryDefaultModel[]>(
        `${environment.API}v1/config/enquiry_defaults`
      )
      .pipe(
        map(templates => {
          const result: LabelValueModel[] = [];

          templates.map(template => {
            const singleProduct: LabelValueModel = {
              label: template.contact_person!,
              value: template.id!,
            };

            result.push(singleProduct);
          });
          return result;
        })
      );
  }

  getEnquiryDefaultsById$(
    id: string
  ): Observable<EnquiryDefaultModel | undefined> {
    return this.http.get<EnquiryDefaultModel>(
      `${environment.API}v1/config/enquiry_default/${id}`
    );
  }

  getProducts$(): Observable<LabelValueModel[]> {
    return this.http
      .get<ProductConfigModel[]>(`${environment.API}v1/config/products`)
      .pipe(
        map(products => {
          const result: LabelValueModel[] = [];

          products.map(product => {
            const singleProduct: LabelValueModel = {
              label: product.key,
              value: product.id!,
            };

            result.push(singleProduct);
          });
          return result;
        })
      );
  }

  getProductById$(id: string): Observable<ProductConfigModel | undefined> {
    return this.http.get<ProductConfigModel>(
      `${environment.API}v1/config/product/${id}`
    );
  }

  // POST
  saveGeneralSettings$(generalSettingsModel: GeneralSettingsModel) {
    return this.debounce(() => {
      return this.http.put<GeneralSettingsModel>(
        `${environment.API}v1/config/settings`,
        generalSettingsModel
      );
    });
  }

  saveEnquiryDefault$(
    enquiryDefaultModel: EnquiryDefaultModel
  ): Observable<any> {
    return this.debounce(() => {
      return enquiryDefaultModel.id
        ? this.http.put<EnquiryDefaultModel>(
            `${environment.API}v1/config/enquiry_default/${enquiryDefaultModel.id}`,
            enquiryDefaultModel
          )
        : this.http.post<EnquiryDefaultModel>(
            `${environment.API}v1/config/enquiry_default`,
            enquiryDefaultModel
          );
    });
  }

  deleteEnquiryDefault$(id: string): Observable<boolean> {
    return this.debounce(() => {
      return this.http.delete<boolean>(
        `${environment.API}v1/config/enquiry_default/${id}`
      );
    });
  }

  saveProduct$(productConfiguration: ProductConfigModel) {
    return this.debounce(() => {
      return productConfiguration.id
        ? this.http.put<EnquiryDefaultModel>(
            `${environment.API}v1/config/product/${productConfiguration.id}`,
            productConfiguration
          )
        : this.http.post<EnquiryDefaultModel>(
            `${environment.API}v1/config/product`,
            productConfiguration
          );
    });
  }

  deleteProduct$(id: string): Observable<boolean> {
    return this.debounce(() => {
      return this.http.delete<boolean>(
        `${environment.API}v1/config/product/${id}`
      );
    });
  }
}
