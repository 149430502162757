
<eon-ui-icon [name]="params!.value ? 'checkbox_checked' : 'checkbox'" (click)="showModal()"></eon-ui-icon>


<gep-modal
  [headline]="'trackerPage.masterDataPage.activationModal.header' | translate"
  [description]="'trackerPage.masterDataPage.activationModal.description' | translate : {status: newValue ? ('trackerPage.activated' | translate) : ('trackerPage.deactivated' | translate)}"
  [showClosingX]="true"
  [closeButtonText]="'no' | translate"
  [positiveButtonText]="'yes' | translate"
  [visible]="modalVisible"
  (closedButtonClicked)="this.modalVisible = false"
  (positiveButtonClicked)="toggleTrackerActivation()"
></gep-modal>
