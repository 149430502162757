<main>
  <div class="welcome_user">
    <gep-welcome-message></gep-welcome-message>
  </div>
  <div class="no-support-text">
    All requests without support - Alle Anfragen ohne Support
  </div>
  <gep-pagination-table-wrapper
    [allowControls]="[]"
    [dataRetriever]="dataRetriever"
    [selectedColumns]="selectedColumns"
    [availableColumns]="availableColumns"
    [defaultOrderBy]="defaultOrderBy"
  >
  </gep-pagination-table-wrapper>
  <h3>{{ 'legend' | translate }}</h3>
  <div class="legend">
    <gep-product-specs
      [origin]="'origin' | translate"
      [energySource]="'energy_source' | translate"
      [systemAge]="'system_age' | translate"
    ></gep-product-specs>
  </div>
</main>

<ng-template #priceTemplate let-column="column" let-row="row">
  <form (submit)="submitPrice(row, price.value)" class="price-form">
    <gep-input-deprecated
      [disabled]="status(row).over_due"
      #price
      name="price"
      size="small"
      type="text"
      [trailingIcon]="true"
      trailingIconName="currency_euro"
      [value]="row.offers[0]?.price"
      (valueChanged)="row._submitted = false"
      (keyup)="$event.key === 'Enter' && submitPrice(row, price.value)"
    >
    </gep-input-deprecated>
    <eon-ui-button
      [disabled]="isSubmitDisabled(row, price.value)"
      [preloader]="status(row).submitting"
      input-type="submit"
      scheme="turquoise"
      text="{{ (status(row).submitted ? 'SENT' : 'SEND') | translate }}"
      size="small"
    >
    </eon-ui-button>
  </form>
</ng-template>

<ng-template #bindingDeadlineTemplate let-column="column" let-row="row">
  <div class="binding-deadline">
    <p>{{ row[column.name] | date: 'dd.MM.yy HH:mm' }}</p>
    <p>{{ row[column.name] | timeLeft }}</p>
  </div>
</ng-template>

<ng-template #commentTemplate let-column="column" let-row="row">
  <ng-container *ngIf="row[column.name]">
    <div class="comment-output">
      {{ row.comment | shortenStringReadMore: 50 }}
      <eon-ui-button
        scheme="red"
        size="small"
        icon="chat"
        (click)="openComment(row)"
      ></eon-ui-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #productTemplate let-column="column" let-row="row">
  {{ row.product_key | translate }}
</ng-template>

<ng-template #productSpecificationTemplate let-column="column" let-row="row">
  <gep-product-specs
    [origin]="row.region"
    [energySource]="row.energy_source"
    [systemAge]="row.system_age"
  ></gep-product-specs>
</ng-template>

<ng-template #quantityInMWhTemplate let-column="column" let-row="row">
  <p>{{ row[column.name] | quantity: QuantityUnit.MWh }}</p>
</ng-template>

<ng-template #quantityInTonnsTemplate let-column="column" let-row="row">
  <p>{{ row[column.name] | quantity: QuantityUnit.t}} </p>
</ng-template>

<ng-template #timeOfPricingTemplate let-column="column" let-row="row">
  {{ row[column.name] | date: 'dd.MM.yy HH:mm' }}
</ng-template>

<eon-ui-modal
  [visible]="commentModal.visible"
  modal-id="cp-comment-modal"
  copytext="{{ commentModal.text }}"
  show-closing-x="true"
  (modalClose)="commentModal.visible = false"
>
</eon-ui-modal>
