import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DealModel, Status } from '../../../../models/deal.model';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'gep-offers-actions-renderer',
  styleUrls: ['offers-actions-renderer.component.scss'],
  templateUrl: './offers-actions-renderer.component.html',
})
export class OffersActionsRendererComponent
  implements ICellRendererAngularComp, OnDestroy
{
  constructor(private router: Router) {}

  protected readonly STATUS = Status;

  _destroy$ = new Subject<void>();
  row?: DealModel;
  params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.row = params.node.data;
  }
  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  showInformModal(deal: DealModel) {
    this.params.context.componentParent.showInformModal(deal);
  }

  showCloseDealModal(deal: DealModel) {
    this.params.context.componentParent.showCloseDealModal(deal);
  }

  cloneDeal(deal: DealModel) {
    this.params.context.componentParent.cloneDeal(deal);
  }

  resetToPriceCollection(deal: DealModel) {
    this.params.context.componentParent.resetToPriceCollection(deal);
  }

  edit(deal: DealModel) {
    this.router.navigateByUrl(`/enquiry/${deal!.id}`);
  }

  cancelDeal(deal: DealModel) {
    this.params.context.componentParent.cancelDeal(deal);
  }
}
