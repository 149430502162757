<div class="d-flex align-items-center">
  <eon-ui-checkbox
    [disabled]="submitting"
    id="check"
    ngDefaultControl
    [checked]="row?.quantity_delivery_confirmed ?? false"
    (valueChanged)="checkClicked($any($event).detail)"
  ></eon-ui-checkbox>
  <input
    [ngModel]="row?.quantity_delivery_subaccount"
    (ngModelChange)="row!.quantity_delivery_subaccount = $event"
    [disabled]="submitting"
    type="text"
    size="small"
    class="ms-lg-2 me-lg-2"
    >
  <gep-button
    [disabled]="submitting"
    [label]="'SAVE' | translate"
    [size]="ButtonSize.Small"
    (click)="openConfirmModal()">
  </gep-button>
</div>

<gep-modal
  [visible]="confirmOneModal.visible"
  [headline]="'consignments.acceptConsignment' | translate"
  [description]="'consignments.questionForConsignmentSingle' | translate"
  [positiveButtonStyle]="Style.Highlight"
  [closeButtonText]="'no' | translate"
  [positiveButtonText]="'yes' | translate"
  (positiveButtonClicked)="saveCheck()"
  (closedButtonClicked)="confirmOneModal.close()"
  [showClosingX]="true"
  modalId="show-consignment-confirmation"
></gep-modal>
