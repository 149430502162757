<section>
  <div class="heading">
    <eon-ui-headline
      [text]="'dealDetails.dealDetails' | translate"
      size="h4"
      scheme="darkgrey"
    ></eon-ui-headline>
  </div>
</section>


<div *ngIf="deal">
  <div class="d-flex justify-content-end">
    <gep-button class="edit-deal-button" [label]="'editDeal.editDeal' | translate" (clicked)="goToEditDealPage(deal.id)"></gep-button>
  </div>
  <table>
    <tr>
      <td>{{ 'dealDetails.id' | translate }}</td>
      <td>{{ deal.id }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.status' | translate }}</td>
      <td>{{ deal.status }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.quantity' | translate }}</td>
      <td>{{ deal.quantityInMWh ? deal.quantityInMWh + " MWh" : deal.quantityInTonns + " t" }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.price' | translate }}</td>
      <td>{{ deal.price }} {{ deal.price_unit }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.dealType' | translate }}</td>
      <td>{{ deal.deal_type }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.createdAt' | translate }}</td>
      <td>{{ deal.created_at | date : 'dd.MM.yyyy HH:mm' }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.updatedAt' | translate }}</td>
      <td>{{ deal.updated_at | date : 'dd.MM.yyyy HH:mm' }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.dealId' | translate }}</td>
      <td>{{ deal.deal_id }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.closedDate' | translate }}</td>
      <td>{{ deal.closed_date | date : 'dd.MM.yyyy HH:mm' }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.productKey' | translate }}</td>
      <td>{{ deal.product_key }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.year' | translate }}</td>
      <td>{{ deal.year }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.completionDate' | translate }}</td>
      <td>{{ deal.completion_date | date : 'dd.MM.yyyy HH:mm' }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.bindingPeriodStart' | translate }}</td>
      <td>{{ deal.binding_period_start | date : 'dd.MM.yyyy HH:mm' }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.bindingPeriodEnd' | translate }}</td>
      <td>{{ deal.binding_period_end | date : 'dd.MM.yyyy HH:mm' }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.customer' | translate }}</td>
      <td>{{ deal.customer }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.address' | translate }}</td>
      <td>{{ deal.address }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.contactPerson' | translate }}</td>
      <td>{{ deal.contact_person }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.segment' | translate }}</td>
      <td>{{ deal.segment }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.region' | translate }}</td>
      <td>{{ deal.region }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.redistributor' | translate }}</td>
      <td>
        {{ deal.redistributor ? 'yes' : 'no' }}
      </td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.systemAge' | translate }}</td>
      <td>{{ deal.system_age }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.energySource' | translate }}</td>
      <td>{{ deal.energy_source }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.vg' | translate }}</td>
      <td>{{ deal.vg }}</td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.statementCancellation' | translate }}</td>
      <td>
        {{ deal.statement_cancellation ? 'yes' : 'no' }}
      </td>
    </tr>
    <tr>
      <td>{{ 'dealDetails.comment' | translate }}</td>
      <td>{{ deal.comment }}</td>
    </tr>
    <tr *ngIf="deal.offers && deal.offers.length > 0">
      <td>{{ 'PRICE_OFFERS' | translate }}</td>
      <td>
        <table>
          <thead>
            <tr>
              <th>{{'counterpart_company' | translate}}</th>
              <th>{{'OFFER_DATE' | translate }}</th>
              <th>{{'price' | translate}}</th>
              <th>{{'BOUND_DATE' | translate}}<br>{{'BOUND_BY' | translate}}</th>
            </tr>
          </thead>
          <tr *ngFor="let offer of deal.offers">
            <td>{{offer.counterpart_name}} ({{offer.counterpart_company}})</td>
            <td>{{offer.created_at | date : 'dd.MM.yyyy HH:mm'}}</td>
            <td>{{offer.price}} €</td>
            <td>{{offer.bound_date ? (offer.bound_date | date : 'dd.MM.yyyy HH:mm') : ""}} {{offer.bound_by ? "- " + offer.bound_by : ""}}</td>
          </tr>
        </table>
      </td>
    </tr>
    <tr *ngIf="deal.mstatus && deal.mstatus.length > 0">
      <td>{{'mstatus' | translate}}</td>
      <td>
        <p *ngFor="let stat of deal.mstatus">{{stat}}</p>
      </td>
    </tr>
  </table>
</div>

<div class="no-deal" *ngIf="!deal">
  <h1>{{ 'dealDetails.noDeal' | translate }}</h1>
</div>
