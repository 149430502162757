import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

export const GreendeskGuard: CanActivateChildFn = (childRoute, state) => {
  const router = inject(Router);
  const authService = inject(MsalService);

  const activeAccount = authService.instance.getActiveAccount();
  const roles = (activeAccount?.idTokenClaims as { roles: string[] })?.roles;
  const isGreendeskUser = roles?.length == 1 && roles[0] == 'GREENDESK';

  if (isGreendeskUser && childRoute.url[0]?.path?.includes('submit-offer')) {
    router.navigate(['/dashboard']);
    return false;
  }

  return true;
};
