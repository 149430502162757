import { Component, OnInit } from '@angular/core';
import { DealModel } from '../../models/deal.model';
import { Observable } from 'rxjs';
import { ColDef } from 'ag-grid-community';
import { DealSearchService } from '../../modules/deal-search/services/deal-search.service';
import { ColumnDefinitionService } from '../../modules/ag-grid/column-definition.service';

@Component({
  selector: 'gep-my-deals-page',
  templateUrl: './my-deals-page.component.html',
  styleUrls: ['./my-deals-page.component.scss'],
})
export class MyDealsPageComponent implements OnInit {
  rowData$?: Observable<DealModel[]>;

  constructor(
    private dealSearchService: DealSearchService,
    private columnDefinitionService: ColumnDefinitionService
  ) {}

  ngOnInit() {
    this.rowData$ = this.dealSearchService.getDealsForCounterpart();
  }

  colDefs: ColDef<DealModel>[] = [
    {
      field: 'id',
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    this.columnDefinitionService.closedDateColumn(),
    this.columnDefinitionService.yearColumn(),
    this.columnDefinitionService.quantityInMWhColumn(),
    this.columnDefinitionService.quantityInTonnsColumn(),
    this.columnDefinitionService.priceColumn(),
    this.columnDefinitionService.counterpartCompanyColumn(true),
    this.columnDefinitionService.energySourceColumn(),
    this.columnDefinitionService.regionColumn(),
    this.columnDefinitionService.systemAgeColumn(),
    this.columnDefinitionService.quantityDeliveryConfirmedColumn(),
    this.columnDefinitionService.quantityDeliveryDateColumn(),
    this.columnDefinitionService.bindingPeriodStartColumn(),
    this.columnDefinitionService.bindingPeriodEndColumn(),
    this.columnDefinitionService.commentColumn(),
    this.columnDefinitionService.statusColumn(),
  ];
}
