export enum SCHEDULE_TYPES {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum SCHEDULED_DAYS {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
}

export enum TRACKER_DEAL_TYPES {
  ENQUIRY_SELL = 'ENQUIRY_SELL',
}

export type TrackerModel = {
  id?: string;
  name?: string;
  customer?: string;
  vg?: string;
  segment?: string;
  active: boolean;

  productKey?: string;
  year?: number;
  psiId?: string;
  dealType?: TRACKER_DEAL_TYPES;

  sourcingStart?: Date | string;
  sourcingEnd?: Date | string;
  totalQuantity?: number;
  closedQuantity?: number;

  scheduleType?: SCHEDULE_TYPES;
  scheduledDay?: SCHEDULED_DAYS;
  scheduledDayOfMonth?: number;
  schedulingTime?: string;

  holidayCalendarName?: string;
};
