import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackerSummaryComponent } from './components/tracker-summary/tracker-summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { GepFormsModule } from '../gep-forms/gep-forms.module';
import { ComponentsModule } from '../../components/components.module';
import { EonUiComponentsAngularModule } from '@eon-ui/eon-ui-components-angular';
import { GepControlsModule } from '../gep-controls/gep-controls.module';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [TrackerSummaryComponent],
  exports: [TrackerSummaryComponent],
  imports: [
    CommonModule,
    TranslateModule,
    GepFormsModule,
    ComponentsModule,
    EonUiComponentsAngularModule,
    GepControlsModule,
    PipesModule,
  ],
})
export class TrackerModule {}
