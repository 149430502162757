import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DealModel, StatementCancellationUpdate } from '../models/deal.model';
import { PageModel } from '../models/page.model';
import { environment } from '../../environments/environment';
import { DealConsignment } from '../models/consignments.model';
import { BatchDealCancellationResource } from '../models/batch-deal-cancellation.model';
import { DealSearchSortOption } from '../modules/deal-search/model/deal-search-sort-option';
import { DealDocId } from '../models/deal-doc-id.model';
import { runOnceUntilComplete } from '../effects/run-once.effect';

export enum DEAL_TYPES {
  DEAL_TYPE_NONE = '',
  DEAL_TYPE_ENQUIRY_BUY = 'ENQUIRY_BUY',
  DEAL_TYPE_ENQUIRY_BUY_SELL = 'ENQUIRY_BUY_SELL',
  DEAL_TYPE_ENQUIRY_SELL = 'ENQUIRY_SELL',
  DEAL_TYPE_PSI_SELL = 'PSI_SELL',
  DEAL_TYPE_LEGACY_BUY = 'LEGACY_BUY',
  DEAL_TYPE_LEGACY_BUY_SELL = 'LEGACY_BUY_SELL',
  DEAL_TYPE_LEGACY_SELL = 'LEGACY_SELL',
  MEMPHIS_BUY = 'MEMPHIS_BUY',
}

@Injectable({
  providedIn: 'root',
})
export class DealService {
  constructor(private http: HttpClient) {}

  debounce = runOnceUntilComplete();

  /*
  GET
   */
  public getDealsPsiSales(
    page = 0,
    page_size = 20,
    search: { [column: string]: string } = {},
    orderBy: DealSearchSortOption[]
  ): Observable<PageModel<DealModel>> {
    return this.http.get<PageModel<DealModel>>(
      `${
        environment.API
      }v1/deals/psi-sales?page=${page}&page_size=${page_size}&orderby=${this.stringifyOrderBy(
        orderBy
      )}&query=${encodeURI(JSON.stringify(search))}`
    );
  }

  public getDealsConsignments(
    page = 0,
    page_size = 20,
    search: { [column: string]: string } = {},
    orderBy: DealSearchSortOption[]
  ): Observable<PageModel<DealModel>> {
    return this.http.get<PageModel<DealModel>>(
      `${
        environment.API
      }v1/deals/consignments?page=${page}&page_size=${page_size}&orderby=${this.stringifyOrderBy(
        orderBy
      )}&query=${encodeURI(JSON.stringify(search))}`
    );
  }

  public getDealsOffers(
    page = 0,
    page_size = 20,
    search: { [column: string]: string } = {},
    orderBy: DealSearchSortOption[] = []
  ): Observable<PageModel<DealModel>> {
    return this.http.get<PageModel<DealModel>>(
      `${
        environment.API
      }v1/deals/offers?page=${page}&page_size=${page_size}&orderby=${this.stringifyOrderBy(
        orderBy
      )}&query=${encodeURI(JSON.stringify(search))}`
    );
  }

  public getDealsOffersCp(
    page = 0,
    page_size = 20,
    search: { [column: string]: string } = {},
    orderBy: DealSearchSortOption[]
  ): Observable<PageModel<DealModel>> {
    return this.http.get<PageModel<DealModel>>(
      `${
        environment.API
      }v1/deals/offers-cp?page=${page}&page_size=${page_size}&orderby=${this.stringifyOrderBy(
        orderBy
      )}&query=${encodeURI(JSON.stringify(search))}`
    );
  }

  public getDealById(dealId: string): Observable<DealModel | undefined> {
    return this.http.get<DealModel>(`${environment.API}v1/deals/${dealId}`);
  }

  /*
  CREATE
   */

  createDeals(deals: DealModel[]): Observable<string[]> {
    return this.debounce(() => {
      return this.http.post<string[]>(`${environment.API}v1/deals`, deals);
    });
  }

  public createDealOffer(
    doc_id: string,
    price: number,
    user_oid: string | null = null,
    manual: boolean = true
  ): Observable<boolean> {
    return this.debounce(() => {
      return this.http.post<boolean>(
        `${environment.API}v1/deals/${doc_id}/offers?manual=${manual}`,
        {
          price,
          user_oid,
        }
      );
    });
  }

  /*
  UPDATE
   */
  public updateDeal(doc_id: string, dealUpdate: object): Observable<boolean> {
    return this.debounce(() => {
      return this.http.put<boolean>(
        `${environment.API}v1/deals/${doc_id}`,
        dealUpdate
      );
    });
  }

  public updateDealConsignment(
    doc_id: string,
    dealUpdate: object
  ): Observable<boolean> {
    return this.debounce(() => {
      return this.http.put<boolean>(
        `${environment.API}v1/deals/${doc_id}/consignment`,
        dealUpdate
      );
    });
  }

  public cloneDeal(deal: DealModel): Observable<DealDocId> {
    return this.debounce(() => {
      return this.http.put<DealDocId>(
        `${environment.API}v1/deals/${deal.id}/clone`,
        ''
      );
    });
  }

  /**
   * Update deal consignments in a batch
   */
  public updateDealConsignmentBatched(
    consignments: DealConsignment[]
  ): Observable<boolean> {
    return this.debounce(() => {
      return this.http.put<boolean>(
        `${environment.API}v1/deals/consignments`,
        consignments
      );
    });
  }

  public updateDealInvalidation(
    doc_id: string,
    dealUpdate: any
  ): Observable<boolean> {
    return this.debounce(() => {
      return this.http.put<boolean>(
        `${environment.API}v1/deals/${doc_id}/invalidation`,
        dealUpdate
      );
    });
  }

  public updateDealInvalidations(
    invalidations: Array<
      Pick<DealModel, 'id' | 'quantity_cancelled'> & {
        quantity_cancellation_date: string;
      }
    >
  ): Observable<boolean> {
    return this.debounce(() => {
      return this.http.post<boolean>(
        `${environment.API}v1/deals/invalidations`,
        invalidations
      );
    });
  }

  public updateDealStatus(
    doc_id: string,
    dealUpdate: object
  ): Observable<void> {
    return this.debounce(() => {
      return this.http.patch<void>(
        `${environment.API}v1/deals/${doc_id}/status`,
        dealUpdate
      );
    });
  }

  public updateCancellationStatement(
    doc_id: string,
    dealUpdate: object
  ): Observable<StatementCancellationUpdate> {
    return this.debounce(() => {
      return this.http.put<StatementCancellationUpdate>(
        `${environment.API}v1/deals/${doc_id}/cancellation`,
        dealUpdate
      );
    });
  }

  public cancelDeals(
    batchDealCancellationResource: BatchDealCancellationResource
  ): Observable<void> {
    return this.debounce(() => {
      return this.http.post<void>(
        `${environment.API}v1/deals/cancellations/batch-cancel`,
        batchDealCancellationResource
      );
    });
  }

  private stringifyOrderBy(orderBy: DealSearchSortOption[]) {
    return orderBy.map(x => x.field + ' ' + x.direction).join(',');
  }
}
