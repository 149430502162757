import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { TrackerModel } from '../../../../models/tracker.model';
import { TrackerService } from '../../../../services/tracker/tracker.service';
import { ButtonSize } from '../../../gep-controls/models/button-size';
import { Icon } from '../../../gep-controls/models/icon';

@Component({
  selector: 'gep-master-data-actions-renderer',
  templateUrl: './master-data-actions-renderer.component.html',
  styleUrls: ['./master-data-actions-renderer.component.scss'],
})
export class MasterDataActionsRenderer implements ICellRendererAngularComp {
  params: ICellRendererParams<TrackerModel> | undefined;
  showModal: boolean = false;
  protected readonly Icon = Icon;
  protected readonly ButtonSize = ButtonSize;

  constructor(private router: Router, private trackerService: TrackerService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams<TrackerModel>): boolean {
    return false;
  }

  openEditTrackerPage() {
    this.router.navigate(['/tracker/edit', this.params?.data?.id]);
  }

  deleteTracker(params: ICellRendererParams<TrackerModel> | undefined) {
    this.trackerService.deleteTracker$(params?.data).subscribe(_ => {
      const rowNode = this.params?.node;
      this.params!.api.applyTransaction({ remove: [rowNode!.data!] });
      this.showModal = false;
    });
  }

  openTrackerDeleteModal() {
    this.showModal = true;
  }
}
