import { Component, Input } from '@angular/core';
import { TrackerSummary } from '../../../../models/tracker-summary.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gep-tracker-summary',
  templateUrl: './tracker-summary.component.html',
  styleUrls: ['./tracker-summary.component.scss'],
})
export class TrackerSummaryComponent {
  slicesVisible: boolean = false;

  @Input()
  trackerSummary?: TrackerSummary;

  locale = '';

  constructor(private translate: TranslateService) {
    if (this.translate.getBrowserLang() !== undefined) {
      this.locale = this.translate.getBrowserLang()!;
    }
  }

  toggleSlices(): void {
    this.slicesVisible = !this.slicesVisible;
  }
}
