import { HorizonStatus } from './horizon-status.model';
import { OfferModel } from './offer.model';

export enum Status {
  IMPORTED = 'IMPORTED',
  CREATED = 'CREATED',
  PRICE_COLLECTION = 'PRICE_COLLECTION',
  BINDING = 'BINDING',
  CLOSED = 'CLOSED',

  CANCELLED = 'CANCELLED',
}

export class DealModel {
  id?: string;
  deal_id?: string;
  deal_type?: string;
  created_at?: Date;
  updated_at?: Date;
  address?: string;
  binding_period_start?: Date;
  binding_period_end?: Date;
  delivery_start?: string;
  delivery_end?: string;
  completion_date?: string;
  contact_person?: string;
  contract_id?: string; // TODO remove -> deal_id
  customer?: string;
  kam_support?: string; // TODO Remove also from memphis data -> contact person
  product_key?: string;
  closed_date?: Date;
  quantityInMWh?: number;
  quantityInTonns?: number;
  price?: number;
  price_transaction?: number;
  price_margin?: number;
  price_unit?: string;
  redistributor?: boolean;
  section?: string;
  segment?: string;
  system_age?: string;
  energy_source?: string;
  region?: string;
  vg?: string;
  year?: number;
  quantity_delivery_confirmed?: boolean;
  quantity_delivery_date?: Date;
  quantity_delivery_subaccount?: string;
  quantity_cancelled?: boolean;
  quantity_cancellation_date?: Date;
  status?: Status;
  comment?: string;
  offers?: Array<OfferModel>;
  bound_offer?: OfferModel;
  statement_cancellation?: boolean;
  malo_id?: string;
  horizon_status?: HorizonStatus;
  counterpart_company?: string;
  batch_cancellation_id?: string;
  mstatus?: [];
}

export type StatementCancellationUpdate = {
  statement_cancellation: boolean;
};
