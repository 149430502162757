<eon-ui-date-picker
  [attr.value]="valueFormatted$ | async"
  [attr.close-on-select]="true"
  [attr.label]="label"
  [attr.weekends]="showWeekends"
  [attr.date-format]="dateFormat"
  [disabled]="disabled"
  [attr.scheme]="'turquoiseOnLightgrey'"
  [attr.min]="minDate$ | async"
  [attr.size]="size"
  (valueChanged)="onValueChanged($event)"
></eon-ui-date-picker>
