<header *ngIf="profile$ | async as profile">
  <a href="/dashboard" class="logo-link">
    <img class="logo" src="../../../assets/img/logo_gep.png" />
  </a>

  <ul class="main-menu">
    <!-- Dashboard -->
    <li
      *ngIf="
        profile.roles
          | hasAnyRole : ROLE.ADMIN_SYS : ROLE.GREENDESK : ROLE.READONLY
      "
    >
      <a [routerLink]="'/dashboard'" [routerLinkActive]="'active'">{{
        'dashboard' | translate
      }}</a>
    </li>
    <!-- Deals -->
    <li
      *ngIf="
        profile.roles
          | hasAnyRole
            : ROLE.ADMIN_SYS
            : ROLE.GREENDESK
            : ROLE.PRODUCER
            : ROLE.READONLY
      "
    >
      <a
        [routerLink]="
          (profile.roles | hasAnyRole : ROLE.PRODUCER) ? '/deals' : '/offers'
        "
        routerLinkActive
        #offers="routerLinkActive"
        [className]="deals.isActive || offers.isActive ? 'active' : ''"
      >
        {{ 'DEALS' | translate }}
      </a>
      <!-- This is a workaround for also marking the above link is active because both links lay under the UBA page -->
      <a
        [routerLink]="'/deals'"
        routerLinkActive
        #deals="routerLinkActive"
        style="display: none"
      ></a>
    </li>
    <!-- Tracker -->
    <li
      *ngIf="
        profile.roles
          | hasAnyRole
            : ROLE.ADMIN_SYS
            : ROLE.GREENDESK
            : ROLE.PRODUCER
            : ROLE.READONLY
      "
    >
      <a [routerLink]="'/tracker'" [routerLinkActive]="'active'">Tracker</a>
    </li>
    <!-- Offers   -->
    <li
      *ngIf="
        profile.roles
          | hasAnyRole : ROLE.ADMIN_SYS : ROLE.EXTERNAL_PARTY : ROLE.READONLY
      "
    >
      <a [routerLink]="'/submit-offer'" [routerLinkActive]="'active'">
        {{ 'SUBMIT_OFFER' | translate }}
      </a>
    </li>
    <ng-container
      *gepFeatureFlag="'.appconfig.featureflag/my-deals'">
      <li
        *ngIf="
        profile.roles
          | hasAnyRole : ROLE.EXTERNAL_PARTY
      "
      >
        <a [routerLink]="'/my-deals'" [routerLinkActive]="'active'">
          {{ 'myDeals' | translate }}
        </a>
      </li>
    </ng-container>

    <!-- UBA (Default Page: consignments) -->
    <li
      *ngIf="
        profile.roles
          | hasAnyRole : ROLE.ADMIN_SYS : ROLE.GREENDESK : ROLE.READONLY
      "
    >
      <a
        [routerLink]="'/consignments'"
        routerLinkActive
        #consignmentsActive="routerLinkActive"
        [className]="
          invalidationsActive.isActive || consignmentsActive.isActive || u20DeliveriesActive.isActive
            ? 'active'
            : ''
        "
      >
        {{ 'UBA' | translate }}
      </a>
      <!-- This is a workaround for also marking the above link is active because both links lay under the UBA page -->
      <a
        [routerLink]="'/invalidations'"
        routerLinkActive
        #invalidationsActive="routerLinkActive"
        style="display: none"
      ></a>
      <a
        [routerLink]="'/deliveries'"
        routerLinkActive
        #u20DeliveriesActive="routerLinkActive"
        style="display: none"
      ></a>
    </li>
    <li
      *ngIf="
        profile.roles
          | hasAnyRole
            : ROLE.ADMIN_SYS
            : ROLE.GREENDESK
            : ROLE.ADMIN_GEP
      ">
      <a [routerLink]="'/admin/edit-deal'" [routerLinkActive]="'active'">{{
          'admin' | translate
        }}</a>
    </li>
    <ng-container *gepFeatureFlag="'.appconfig.featureflag/settings-page'">
    <li
      *ngIf="
        profile.roles
          | hasAnyRole
            : ROLE.ADMIN_SYS
            : ROLE.ADMIN_GEP
            : ROLE.GREENDESK
      ">
      <a [routerLink]="'/settings/general'" [routerLinkActive]="'active'">{{
          'settings.headline' | translate
        }}</a>
    </li>
    </ng-container>
  </ul>

  <div class="" style="display: flex">
    <gep-health-status *ngIf="
        profile.roles
          | hasAnyRole
            : ROLE.ADMIN_SYS
            : ROLE.GREENDESK
            : ROLE.ADMIN_GEP
            : ROLE.READONLY
      "></gep-health-status>

    <div
      ngbDropdown
      *ngIf="{ _: profile.id }"
      (openChange)="toggleProfileDropdown()"
      class="d-flex"
    >
      <button
        class="account-button"
        id="dropdownUser"
        ngbDropdownToggle
        [class.active]="profileDropdownIsOpen"
      >
        <eon-ui-icon name="user" scheme="bordeaux"></eon-ui-icon>
      </button>

      <div
        ngbDropdownMenu
        aria-labelledby="dropdownUser"
        class="account-content"
      >
        <eon-ui-headline text="Account" size="h5"></eon-ui-headline>
        <p>
          {{ 'ACCOUNT_NOTICE' | translate : { fullName: profile.displayName } }}
          <br />
          <span class="email">{{ email$ | async }}</span>
        </p>
        <eon-ui-button
          (click)="logout()"
          text="{{ 'Logout' | translate }}"
        ></eon-ui-button>
      </div>
    </div>
  </div>
</header>
