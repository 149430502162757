import { Pipe, PipeTransform } from '@angular/core';
import { DealModel } from '../models/deal.model';

@Pipe({
    name: 'bestPrice',
})
export class BestPricePipe implements PipeTransform {
    transform(deal?: DealModel): string {
        if (!deal || (!deal.quantityInMWh && !deal.quantityInTonns) || !deal.offers?.length) {
            return '-';
        }

        const prices = deal.offers.map(offer => offer.price);
        const quantity = deal.quantityInMWh ?? deal.quantityInTonns;

        const bestPrice = quantity! > 0 ? Math.min(...prices) : Math.max(...prices);

        return `${bestPrice} €`;
  }
}
