<gep-tracker-navbar></gep-tracker-navbar>
<section>
  <div>
    <gep-button
      [label]="'trackerPage.masterDataPage.createTracker' | translate"
      routerLink="/tracker/edit"
      [size]="ButtonSize.Small"
    ></gep-button>
  </div>
  <gep-ag-grid-table
    [colDefs]="colDefs"
    [rowData$]="trackers$"
    height="100vh"
  ></gep-ag-grid-table>
</section>
