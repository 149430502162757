<div *ngIf="trackerSummary?.totalQuantity" class="summary">
  <h2>{{'trackerPage.trackerForm.summary.headline' | translate}}</h2>

  <div class="container">
    <div class="row">
      <div class="col text-bold">{{'trackerPage.trackerForm.summary.totalQuantity' | translate}}:</div>
      <div class="col">{{ trackerSummary?.totalQuantity | number:'':'de-DE' }} MWh</div>
    </div>
    <div class="row">
      <div class="col text-bold">{{'trackerPage.scheduling' | translate}}:</div>
      <div class="col">{{ trackerSummary?.scheduleType }}</div>
    </div>
    <div class="row">
      <div class="col text-bold">{{'trackerPage.trackerForm.summary.timePeriod' | translate}}:</div>
      <div class="col">{{ trackerSummary?.sourcingStart | date }} - {{ trackerSummary?.sourcingEnd | date }}</div>
    </div>
    <div *ngIf="trackerSummary?.scheduledDay" class="row">
      <div class="col text-bold">{{'trackerPage.trackerForm.dayOfWeek' | translate}}:</div>
      <div class="col">{{ trackerSummary?.scheduledDay }}</div>
    </div>
    <div *ngIf="trackerSummary?.scheduledDayOfMonth" class="row">
      <div class="col text-bold">{{'trackerPage.trackerForm.dayOfMonth' | translate}}:</div>
      <div class="col">{{ trackerSummary?.scheduledDayOfMonth }}</div>
    </div>
    <div class="row">
      <div class="col text-bold">{{'trackerPage.trackerForm.summary.slices' | translate}}:</div>
      <div class="col">{{ trackerSummary?.slices?.length }}</div>
    </div>
    <div class="row">
      <div class="col text-bold">{{'trackerPage.trackerForm.summary.dealDetails' | translate}}</div>
      <div class="col">{{ trackerSummary?.productKey }}, {{ trackerSummary?.year }}</div>
    </div>
  </div>

  <div class="mt-md-3 text-center">
    <span *ngIf="!slicesVisible; else hideSlices" (click)="toggleSlices()">
      {{'trackerPage.trackerForm.summary.showSlices' | translate }}
    </span>
  </div>
  <ng-template #hideSlices ><div (click)="toggleSlices()">{{'trackerPage.trackerForm.summary.hideSlices' | translate }}</div></ng-template>

  <div *ngIf="slicesVisible" class="mt-md-3 container">
    <h3>Slices</h3>
    <div class="slices-table">
      <table class="table table-bordered">
        <tbody>
        <tr *ngFor="let slice of trackerSummary?.slices">
          <td>{{slice.conversionDate | date:'EEEE, dd.MM.yyyy':'UTC+3':locale}} <span *ngIf="slice.converted">({{'trackerPage.trackerForm.summary.converted' | translate}})</span></td>
          <td>{{slice.sliceSize | number:'':'de-DE' }} MWh</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
