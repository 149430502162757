import { Injectable } from '@angular/core';
import { TrackerModel } from '../../models/tracker.model';
import { Observable } from 'rxjs';
import { TrackerSlice } from '../../models/tracker-slice.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { TrackerSummary } from '../../models/tracker-summary.model';

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  constructor(private http: HttpClient) {}

  //Master Data
  getTrackers$(): Observable<TrackerModel[]> {
    return this.http.get<TrackerModel[]>(
      `${environment.API}v1/tracker/trackers`
    );
  }

  //Edit Page
  getTrackerById$(id: string | null): Observable<TrackerModel | undefined> {
    return this.http.get<TrackerModel>(
      `${environment.API}v1/tracker/trackers/${id}`
    );
  }

  saveTracker$(tracker: TrackerModel): Observable<boolean> {
    tracker = this.mapDateTimesToDateString(tracker);
    tracker = this.prefillEmptyFields(tracker);

    if (tracker.id) {
      return this.http.put<boolean>(
        `${environment.API}v1/tracker/trackers`,
        tracker
      );
    } else {
      return this.http.post<boolean>(
        `${environment.API}v1/tracker/trackers`,
        tracker
      );
    }
  }

  deleteTracker$(tracker: TrackerModel | undefined): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.API}v1/tracker/trackers/${tracker?.id}`
    );
  }

  // Tracker Preview Result
  getTrackerSummary$(tracker: TrackerModel): Observable<TrackerSummary> {
    tracker = this.mapDateTimesToDateString(tracker);
    tracker = this.prefillEmptyFields(tracker);

    return this.http.post<TrackerSummary>(
      `${environment.API}v1/tracker/summary`,
      tracker
    );
  }

  // Preview Slices
  getAllSlices$(): Observable<TrackerSlice[]> {
    return this.http.get<TrackerSlice[]>(`${environment.API}v1/tracker/slices`);
  }

  mapDateTimesToDateString(model: TrackerModel): TrackerModel {
    if (model.sourcingStart instanceof Date) {
      model.sourcingStart = formatDate(model.sourcingStart, 'yyyy-MM-dd', 'de');
    }

    if (model.sourcingEnd instanceof Date) {
      model.sourcingEnd = formatDate(model.sourcingEnd, 'yyyy-MM-dd', 'de');
    }

    return model;
  }

  prefillEmptyFields(model: TrackerModel): TrackerModel {
    if (model.closedQuantity === undefined || model.active === null) {
      model.active = false;
    }

    if (model.closedQuantity === undefined || model.closedQuantity === null) {
      model.closedQuantity = 0;
    }

    return model;
  }
}
