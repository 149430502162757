<div class="d-flex justify-content-sm-between">
  <div>{{ row | bestPrice }}</div>
  <div *ngIf="row">
    <eon-ui-button
      icon="more"
      size="small"
      scheme="standard"
      (click)="showOffersModal(row)"
      [disabled]="
          row['offers']?.length === 0 && row.status !== Status.PRICE_COLLECTION
        "
    ></eon-ui-button>
  </div>
</div>
