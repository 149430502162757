import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  CounterpartUserModel,
  CounterpartUserNotifyUpdate,
} from '../models/counterpart-user.model';
import { Observable } from 'rxjs';
import { runOnceUntilComplete } from '../effects/run-once.effect';

@Injectable({
  providedIn: 'root',
})
export class CounterpartService {
  constructor(private http: HttpClient) {}

  debounce = runOnceUntilComplete();

  /*
  GET
   */

  public getCounterpartUsers(): Observable<CounterpartUserModel[]> {
    return this.http.get<CounterpartUserModel[]>(
      `${environment.API}v1/counterpart_users`
    );
  }

  public getCounterpartUserByOid(
    oid: string
  ): Observable<CounterpartUserModel> {
    return this.http.get<CounterpartUserModel>(
      `${environment.API}v1/counterpart_users/${oid}`
    );
  }

  /*
  PUT
   */
  public updateCounterpartSettings(
    userId: string,
    data: CounterpartUserNotifyUpdate
  ): Observable<void> {
    return this.debounce(() => {
      return this.http.patch<void>(
        `${environment.API}v1/counterpart_users/${userId}`,
        data
      );
    });
  }
}
