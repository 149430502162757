import { Observable, EMPTY, finalize } from 'rxjs';

/**
 * Prevents the callback from being invoked again while a previous
 * invocation is still in progress (i.e., prevents overlapping requests).
 *
 * @param cb - The callback function that returns an Observable.
 * @returns A debounced function which will call the callback function once and ignore a previous call is still pending.
 */
export const runOnceUntilComplete =() => {
  let isPending = false;

  /**
   * Runs the callback function provided and prevents futher calls
   * until the function is complete
   *
   * @param cb - The callback function that returns an Observable.
   */
  return <T extends any>(cb:  () => Observable<T>) => {
    if (isPending) return EMPTY; // Cast EMPTY to the correct Observable<T>
    isPending = true;
    return (cb()).pipe(finalize(() => (isPending = false)));
  }
};
