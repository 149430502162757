import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GepControlsModule } from '../gep-controls/gep-controls.module';
import { ComponentsModule } from '../../components/components.module';
import { GepFormsModule } from '../gep-forms/gep-forms.module';
import { EditDealPageComponent } from './pages/edit-deal-page/edit-deal-page.component';
import { AdminTabHeaderComponent } from './components/admin-tab-header/admin-tab-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from '../../directives/directives.module';
import { EonUiComponentsAngularModule } from '@eon-ui/eon-ui-components-angular';
import { CommonsModule } from '../commons/commons.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [EditDealPageComponent, AdminTabHeaderComponent],
  imports: [
    CommonModule,
    GepControlsModule,
    ComponentsModule,
    GepFormsModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgbDropdownModule,
    PipesModule,
    DirectivesModule,
    EonUiComponentsAngularModule,
    CommonsModule,
    RouterModule,
  ],
  exports: [EditDealPageComponent, AdminTabHeaderComponent],
})
export class AdminModule {}
